<template>
    <div style="background-color: #EDEDF4;">
        <!-- <el-radio-group v-model="shipno" size="mini" @change="no=shipno,shipnosel()"> -->
        <el-radio-group v-model="shipno" size="mini" @change="shipnosel()">
            <el-radio-button label="7200-1"></el-radio-button>
            <el-radio-button label="7200-2"></el-radio-button>
            <el-radio-button label="7200-3"></el-radio-button>
            <el-radio-button label="7200-4"></el-radio-button>
            <el-radio-button label="9200-1"></el-radio-button>
            <el-radio-button label="9200-2"></el-radio-button>
            <el-radio-button label="25k-1"></el-radio-button>
            <el-radio-button label="25k-2"></el-radio-button>
        </el-radio-group>
    </div>
</template>

<script>
export default {
    data () {
        return {
            shipno: '7200-3',
        }
    },
    methods: {
        shipnosel () {
        this.$http.post('/tp6/public/index.php/Mach/shipnosel', { shipno: this.shipno })
        .then((backdata) => {
            // this.shipno= backdata.data
            // alert(backdata.data)
            this.$emit('refresh', this.shipno)
        })
        }
  }
}
</script>
<style>

</style>