<template>
  <div style="position:relative;width:1405px; top:0px">
    <div v-for="(blockgroup,shipno) in blockinfo" :key="shipno" style="width:1405px;position: relative;">
      <div style="border:solid black 2px; position:absolute;left:0px;bottom:0px">
        <table style="width:550px;">
          <tr v-for="(shipcount,stage) in count" :key="stage" style="line-height:20px">
            <td :class="stage">{{stage}}</td><td>{{shipcount['total']}}</td>
            <td style="text-align:left">
              <span :style="{'text-align':'center','display':'inline-block','background-color':'#00AF00',
                'width':shipcount[shipno]*6+'px'}">{{shipcount[shipno]}}</span>
              <span :style="{'text-align':'center','display':'inline-block','background-color':'#C9B9AF',
                'width':shipcount['total']*6-shipcount[shipno]*6+'px'}">{{shipcount['total']-shipcount[shipno]}}</span>
            </td>
          </tr>
        </table>
      </div>
      <div style="width:1405px;border:solid black 2px;">
        <svg  height="720px" width="1405" style="position:relative;top:-20px">
          <text x="650" y="80" textLength="120" lengthAdjust="spacing" font-size="40">{{shipno}}</text>
          <!-- 罗经甲板围栏 -->
          <rect x="243" y="141" width="165" height="9" style="fill:none;stroke:black;stroke-width:1;"/>
          <rect x="243" y="150" width="165" height="9" style="fill:none;stroke:black;stroke-width:1;"/>
          <rect x="243" y="159" width="165" height="7" style="fill:none;stroke:black;stroke-width:1;"/>
          <!-- 雷达桅 -->
          <polygon points="290,166 295,50 305,50 310,166" :class="blockgroup['雷达桅']" @click="edit(shipno,'雷达桅')"/>
          <polygon points="360,166 363,100 367,100 370,166" :class="blockgroup['雷达桅']" @click="edit(shipno,'雷达桅')"/>
          <!-- 前桅 -->
          <polyline points="1280,300 1289,200 1300,200 1300,300" :class="blockgroup['前桅']" @click="edit(shipno,'前桅')"/>
          <rect x="1283" y="190" width="20" height="10" :class="blockgroup['前桅']" @click="edit(shipno,'前桅')"/>
          <rect x="1293" y="160" width="5" height="30" :class="blockgroup['前桅']" @click="edit(shipno,'前桅')"/>

          <polygon points="135,250 135,165 130,150 185,150 185,250" :class="blockgroup['331分段']" @click="edit(shipno,'331分段')"/>
          <rect x="100" y="250" width="85" height="30" :class="blockgroup['231分段']" @click="edit(shipno,'231分段')"/>
          <rect x="115" y="280" width="70" height="32" :class="blockgroup['221分段']" @click="edit(shipno,'221分段')"/>
          <rect x="185" y="280" width="217" height="32" :class="blockgroup['222分段']" @click="edit(shipno,'222分段')"/>
          <rect x="185" y="250" width="217" height="30" :class="blockgroup['232分段']" @click="edit(shipno,'232分段')"/>
          <rect x="219" y="208" width="183" height="42" :class="blockgroup['241分段']" @click="edit(shipno,'241分段')"/>
          <polygon points="262,166 407,166 402,192 402,208 262,208" :class="blockgroup['251分段']" @click="edit(shipno,'251分段')"/>

          <polygon points="40,313 140,313 45,345" :class="blockgroup['211分段']" @click="edit(shipno,'211分段')"/>
          <polygon points="140,313 140,345 45,345" :class="blockgroup['212分段']" @click="edit(shipno,'212分段')"/>
          <polygon points="45,345 140,345 50,377" :class="blockgroup['611分段']" @click="edit(shipno,'611分段')"/>
          <polygon points="140,345 140,377 50,377" :class="blockgroup['612分段']" @click="edit(shipno,'612分段')"/>
          <polygon points="52,385 50,377 140,377 140,418 110,418 105,400 100,400 85,403 55,403" :class="blockgroup['111分段']" @click="edit(shipno,'111分段')"/>
          <polygon points="55,406 85,406 80,468 57,468" :class="blockgroup['舵叶']" @click="edit(shipno,'舵叶')"/>
          <polygon points="110,418 140,418 140,468 120,468 100,450 100,440 110,418" :class="blockgroup['110分段']" @click="edit(shipno,'110分段')"/>

          <rect x="140" y="450" width="220" height="18" :class="blockgroup['421分段']" @click="edit(shipno,'421分段')"/>
          <rect x="360" y="450" width="90px" height="18px" :class="blockgroup['422分段']" @click="edit(shipno,'422分段')"/>
          <polygon points="140,450 450,398 140,398" :class="blockgroup['623分段']" @click="edit(shipno,'623分段')"/>
          <polygon points="140,450 450,398 450,450" :class="blockgroup['624分段']" @click="edit(shipno,'624分段')"/>
          <polygon points="140,398 140,345 420,345 450,353" :class="blockgroup['621分段']" @click="edit(shipno,'621分段')"/>
          <polygon points="450,398 140,398 450,353" :class="blockgroup['622分段']" @click="edit(shipno,'622分段')"/>
          <polygon points="140,313 140,345 420,313" :class="blockgroup['213分段']" @click="edit(shipno,'213分段')"/>
          <polygon points="420,345 140,345 420,313" :class="blockgroup['214分段']" @click="edit(shipno,'214分段')"/>

          <polygon points="450,408 590,438 590,468 450,468" :class="blockgroup['431分段']" @click="edit(shipno,'431分段')"/>
          <polygon points="450,408 450,353 590,353" :class="blockgroup['531分段']" @click="edit(shipno,'531分段')"/>
          <polygon points="450,408 590,353 590,438" :class="blockgroup['532分段']" @click="edit(shipno,'532分段')"/>
          <polygon points="590,353 590,438 710,353" :class="blockgroup['541分段']" @click="edit(shipno,'541分段')"/>
          <polygon points="590,438 710,353 710,438" :class="blockgroup['542分段']" @click="edit(shipno,'542分段')"/>
          <polygon points="590,438 710,438 590,468" :class="blockgroup['441分段']" @click="edit(shipno,'441分段')"/>
          <polygon points="590,468 710,468 710,438" :class="blockgroup['442分段']" @click="edit(shipno,'442分段')"/>
          <polygon points="710,353 710,438 850,353" :class="blockgroup['551分段']" @click="edit(shipno,'551分段')"/>
          <polygon points="850,353 850,438 710,438" :class="blockgroup['552分段']" @click="edit(shipno,'552分段')"/>
          <polygon points="850,438 710,438 710,468" :class="blockgroup['451分段']" @click="edit(shipno,'451分段')"/>
          <polygon points="850,468 710,468 850,438" :class="blockgroup['452分段']" @click="edit(shipno,'452分段')"/>
          
          <polygon points="850,353 850,438 980,353" :class="blockgroup['561分段']" @click="edit(shipno,'561分段')"/>
          <polygon points="980,353 980,433 935,438 850,438" :class="blockgroup['562分段']" @click="edit(shipno,'562分段')"/>
          <polygon points="850,438 980,433 980,438 850,468" :class="blockgroup['461分段']" @click="edit(shipno,'461分段')"/>
          <polygon points="850,468 980,468 980,438" :class="blockgroup['462分段']" @click="edit(shipno,'462分段')"/>

          <polygon points="980,353 980,433 1120,353" :class="blockgroup['571分段']" @click="edit(shipno,'571分段')"/>
          <polygon points="1120,353 1120,388 980,433" :class="blockgroup['572分段']" @click="edit(shipno,'572分段')"/>
          <polygon points="980,468 1120,468 1120,388 1120,388 980,433" :class="blockgroup['471分段']" @click="edit(shipno,'471分段')"/>

          <polygon points="1120,353 1280,353 1120,438" :class="blockgroup['581分段']" @click="edit(shipno,'581分段')"/>
          <polygon points="1120,438 1280,438 1280,353" :class="blockgroup['582分段']" @click="edit(shipno,'582分段')"/>
          <rect x="1120" y="438" width="160" height="30" :class="blockgroup['481分段']" @click="edit(shipno,'481分段')"/>

          <polygon points="1280,420 1390,420 1360,468 1280,468" :class="blockgroup['191分段']" @click="edit(shipno,'191分段')"/>
          <rect x="1280" y="390" width="110" height="30" :class="blockgroup['692分段']" @click="edit(shipno,'692分段')"/>
          <rect x="1280" y="353" width="110" height="37" :class="blockgroup['691分段']" @click="edit(shipno,'691分段')"/>
          <polygon points="1190,353 1260,312 1400,312 1390,353" :class="blockgroup['215分段']" @click="edit(shipno,'215分段')"/>
          <polygon points="1260,312 1262,300 1402,300 1400,312" :class="blockgroup['341分段']" @click="edit(shipno,'341分段')"/>
          <polygon points="1330,300 1330,284 1349,284 1355,300" :class="blockgroup['364分段']" @click="edit(shipno,'364分段')"/>

          <polyline points="420,313 490,308 1190,308 1260,312" style="fill:none;stroke:black;stroke-width:1"/>

          <polygon points="833,308 833,275 933,275" :class="blockgroup['361分段']" @click="edit(shipno,'361分段')"/>
          <polygon points="833,308 933,308 933,275" :class="blockgroup['362分段']" @click="edit(shipno,'362分段')"/>

          <rect x="450" y="323" width="140" height="30" :class="blockgroup['631分段']" @click="edit(shipno,'631分段')"/>
          <rect x="590" y="323" width="120" height="30" :class="blockgroup['641分段']" @click="edit(shipno,'641分段')"/>
          <rect x="710" y="323" width="140" height="30" :class="blockgroup['651分段']" @click="edit(shipno,'651分段')"/>
          <rect x="850" y="323" width="130" height="30" :class="blockgroup['661分段']" @click="edit(shipno,'661分段')"/>
          <rect x="980" y="323" width="140" height="30" :class="blockgroup['671分段']" @click="edit(shipno,'671分段')"/>
          <rect x="1120" y="323" width="160" height="30" :class="blockgroup['681分段']" @click="edit(shipno,'681分段')"/>

          <rect x="450" y="170" width="120" height="40" :class="blockgroup['731分段']" @click="edit(shipno,'731分段')"/>
          <rect x="606" y="170" width="50" height="40" :class="blockgroup['741分段']" @click="edit(shipno,'741分段')"/>
          <rect x="692" y="170" width="120" height="40" :class="blockgroup['751分段']" @click="edit(shipno,'751分段')"/>
          <rect x="848" y="170" width="120" height="40" :class="blockgroup['761分段']" @click="edit(shipno,'761分段')"/>
          <rect x="1004" y="170" width="120" height="40" :class="blockgroup['771分段']" @click="edit(shipno,'771分段')"/>
          <rect x="1160" y="170" width="120" height="40" :class="blockgroup['781分段']" @click="edit(shipno,'781分段')"/>

          <rect x="570" y="140" width="36" height="100" :class="blockgroup['841分段']" @click="edit(shipno,'841分段')"/>
          <rect x="656" y="140" width="36" height="100" :class="blockgroup['842分段']" @click="edit(shipno,'842分段')"/>
          <rect x="812" y="140" width="36" height="100" :class="blockgroup['851分段']" @click="edit(shipno,'851分段')"/>
          <rect x="968" y="140" width="36" height="100" :class="blockgroup['861分段']" @click="edit(shipno,'861分段')"/>
          <rect x="1124" y="140" width="36" height="100" :class="blockgroup['881分段']" @click="edit(shipno,'881分段')"/>
          <!-- 软管吊 -->
          <polyline points="730,323 730,230 755,230 760,270 760,323"  :class="blockgroup['软管吊']" @click="edit(shipno,'软管吊')"/>

          <text x="140" y="200" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'331分段')">331</text>
          <text x="140" y="270" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'231分段')">231</text>
          <text x="140" y="300" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'221分段')">221</text>
          <text x="285" y="300" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'222分段')">222</text>
          <text x="285" y="270" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'232分段')">232</text>
          <text x="285" y="240" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'241分段')">241</text>
          <text x="285" y="200" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'251分段')">251</text>

          <text x="45" y="330" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'211分段')">211</text>
          <text x="105" y="340" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'212分段')">212</text>
          <text x="50" y="363" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'611分段')">611</text>
          <text x="105" y="371" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'612分段')">612</text>
          <text x="80" y="395" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'111分段')">111</text>
          <text x="105" y="450" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'110分段')">110</text>

          <text x="240" y="465" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'421分段')">421</text>
          <text x="392" y="465" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'422分段')">422</text>
          <text x="220" y="425" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'623分段')">623</text>
          <text x="320" y="438" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'624分段')">624</text>
          <text x="220" y="370" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'621分段')">621</text>
          <text x="320" y="383" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'622分段')">622</text>
          <text x="220" y="330" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'213分段')">213</text>
          <text x="320" y="341" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'214分段')">214</text>

          <text x="500" y="455" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'431分段')">431</text>
          <text x="470" y="380" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'531分段')">531</text>
          <text x="530" y="400" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'532分段')">532</text>
          <text x="615" y="390" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'541分段')">541</text>
          <text x="660" y="410" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'542分段')">542</text>
          <text x="615" y="453" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'441分段')">441</text>
          <text x="660" y="464" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'442分段')">442</text>

          <text x="730" y="390" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'551分段')">551</text>
          <text x="790" y="410" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'552分段')">552</text>
          <text x="730" y="453" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'451分段')">451</text>
          <text x="790" y="464" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'452分段')">452</text>
          <text x="870" y="390" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'561分段')">561</text>
          <text x="930" y="410" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'562分段')">562</text>
          <text x="870" y="453" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'461分段')">461</text>
          <text x="930" y="464" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'462分段')">462</text>

          <text x="1000" y="390" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'571分段')">571</text>
          <text x="1070" y="395" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'572分段')">572</text>
          <text x="1040" y="453" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'471分段')">471</text>
          <text x="1150" y="390" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'581分段')">581</text>
          <text x="1210" y="420" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'582分段')">582</text>
          <text x="1180" y="460" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'481分段')">481</text>

          <text x="1310" y="450" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'191分段')">191</text>
          <text x="1310" y="410" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'692分段')">692</text>
          <text x="1310" y="380" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'691分段')">691</text>
          <text x="1310" y="340" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'215分段')">215</text>
          <text x="1325" y="283" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'363分段')">364</text>
          <text x="1290" y="312" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'341分段')">341</text>

          <text x="845" y="292" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'361分段')">361</text>
          <text x="890" y="304" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'362分段')">362</text>

          <text x="500" y="345" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'631分段')">631</text>
          <text x="640" y="345" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'641分段')">641</text>
          <text x="760" y="345" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'651分段')">651</text>
          <text x="900" y="345" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'661分段')">661</text>
          <text x="1030" y="345" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'671分段')">671</text>
          <text x="1170" y="345" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'681分段')">681</text>

          <text x="500" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'731分段')">731</text>
          <text x="616" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'741分段')">741</text>
          <text x="735" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'751分段')">751</text>
          <text x="890" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'761分段')">761</text>
          <text x="1045" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'771分段')">771</text>
          <text x="1200" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'781分段')">781</text>

          <text x="572" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'841分段')">841</text>
          <text x="658" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'842分段')">842</text>
          <text x="814" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'851分段')">851</text>
          <text x="970" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'861分段')">861</text>
          <text x="1126" y="196" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'881分段')">881</text>

          <text x="280" y="100" textLength="50" lengthAdjust="spacing" @click="edit(shipno,'雷达桅')">雷达桅</text>
          <text x="1280" y="250" textLength="30" lengthAdjust="spacing" @click="edit(shipno,'前桅')">前桅</text>
          <text x="720" y="280" textLength="50" lengthAdjust="spacing" @click="edit(shipno,'软管吊')">软管吊</text>
          <text x="52" y="436" textLength="33" lengthAdjust="spacing" @click="edit(shipno,'舵叶')">舵叶</text>

          <ellipse cx="95" cy="456" rx="5" ry="12" style="fill:none;stroke:black;stroke-width:2"/>
          <ellipse cx="95" cy="434" rx="5" ry="12" style="fill:none;stroke:black;stroke-width:2"/>
        </svg>
      </div>
    </div>
    <el-dialog :visible.sync="hulledit_dialogVisible" width="1030px" :before-close="handleClose">
      <table width="1000px">
        <tr>
          <th width="80px">船号</th><th width="80px">编号</th><th width="120px">id</th><th width="120px">分段</th>
          <th width="120px">名称</th><th width="120px">日期</th><th width="80px">姓名</th><th width="80px">标记</th>
        </tr>
        <tr v-for="(insp,inspkey) in blockmarklog" :key="inspkey">
          <td>{{insp['shipno']}}</td><td>{{insp['inspno']}}</td><td>{{insp['id']}}</td><td>{{insp['pname']}}</td>
          <td>{{insp['name']}}</td><td>{{insp['date']}}</td><td>{{insp['surveyor']}}</td><td>{{insp['result']}}</td>
        </tr>
      </table>
    <br>
      <el-form :model="hullForm" ref="hullForm" label-width="100px" class="demo-dynamic">
        <table width="1000px">
          <tr>
            <td>{{hullForm['shipno']}}</td>
            <td>{{hullForm['pname']}}</td>
            <td>
              <el-form-item label="日期">
                <input type='date' v-model="hullForm.date">
              </el-form-item>
            </td>
            <td>
              <el-form-item label="进度选择" prop="name" :rules="{ required: true, message: '进度不能为空', trigger: 'blur' }">
                <el-select v-model="hullForm.name" style="width:160px;">
                  <el-option v-for="name in statusgroup" :key="name.key" :label="name" :value="name"></el-option>
                </el-select>
              </el-form-item>
            </td>
            <td>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('hullForm')">增加进度点</el-button>
              </el-form-item>
            </td>
          </tr>
        </table>
      </el-form>
    </el-dialog>
    <div id="7200-3" style="height:242px;width:830px;border:solid black 2px; position:absolute;right:-3px;top:487px"></div>
    <div id="7200-4" style="height:242px;width:830px;border:solid black 2px; position:absolute;right:-3px;top:1221px"></div>
    <div style="height:30px;width:560px;position:absolute;left:500px;top:20px">
      <ul>
        <li v-for="(log,id) in blockerrlog['7200-3']" :key="id" style="color:red">
          {{log['pname']}}:{{log['name']}}(有{{log['cnt']}}次记录)
        </li>
      </ul>
    </div>
    <div style="height:30px;width:560px;position:absolute;left:500px;top:770px">
      <ul>
        <li v-for="(log,id) in blockerrlog['7200-4']" :key="id" style="color:red">
          {{log['pname']}}:{{log['name']}}(有{{log['cnt']}}次记录)
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import * as echarts from 'echarts'
  export default {
    data () {
      return {
        blockinfo: '',
        hullForm: {
        date: this.getNowFormatDate(),
        shipno: '7200-3',
        pname: '',
        name: ''
        },
        count: '',
        statusgroup: [
          '2.1分段下料','2.4分段上胎','3.2分段下胎','4.3不锈钢安装','5.6分段完工',
          '5.7分段抛光','8.3分段总组','8.4总组焊后','8.5分段搭载','8.6搭载焊后','8.7货仓清洗'
        ],
        hulledit_dialogVisible: false,
        // var option;
        option: {
          // animation: false,//取消动画效果
          title: {
            text: ''
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['2.1分段下料','2.4分段上胎','3.2分段下胎','4.3不锈钢安装','5.6分段完工','5.7分段抛光','8.3分段总组','8.4总组焊后','8.5分段搭载','8.6搭载焊后','8.7货仓清洗']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // axisLabel: {
            //   rotate: 30
            // },
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: ''
          },
          yAxis: {
            type: 'value',
            min: 0,
            max: 71
          },
          color:['#974706', '#00FF00', '#0070C0', '#FF6600','#FF00FF','#66FFCC','#FFC000','#C4BD97','#FF0000','#00B0F0','#00FFFF'],
          // series: ''
          series: [
            // {
            //   name: '分段下料',
            //   type: 'line',
            //   stack: 'Total',
            //   data: [120, 132, 101, 134, 90, 230, 210]
            // },
            // {
            //   name: '分段上胎',
            //   type: 'line',
            //   stack: 'Total',
            //   data: [220, 182, 191, 234, 290, 330, 310]
            // },
            // {
            //   name: '分段下胎',
            //   type: 'line',
            //   stack: 'Total',
            //   data: [150, 232, 201, 154, 190, 330, 410]
            // },
            // {
            //   name: '不锈钢安装',
            //   type: 'line',
            //   stack: 'Total',
            //   data: [320, 332, 301, 334, 390, 330, 320]
            // },
            // {
            //   name: '分段完工',
            //   type: 'line',
            //   stack: 'Total',
            //   data: [820, 932, 901, 934, 1290, 1330, 1320],
            // }
          ]
        },
        blockerrlog: '',
        blockmarklog: ''
      }
    },
    mounted () {
      this.block_status_count()
      this.getblockinfo()
      this.draw_chart('7200-3')
      this.draw_chart('7200-4')
      this.getblockerrlog()
    },
    methods: {
      getblockinfo () {
      this.$http.post('/tp6/public/index.php/Mach/blockinfo')
        .then((backdata) => {
            this.blockinfo = backdata.data
            // console.log(backdata.data)
          })
      },
      getblockerrlog () {
      this.$http.post('/tp6/public/index.php/Mach/blocklog_err_log')
        .then((backdata) => {
            this.blockerrlog = backdata.data
            // console.log(backdata.data)
          })
      },
      block_status_count () {
      this.$http.post('/tp6/public/index.php/Mach/block_status_count')
        .then((backdata) => {
            this.count = backdata.data
            // console.log(backdata.data)
          })
      },
      edit(shipno,block) {
        this.hullForm['shipno']=shipno
        this.hullForm['pname']=block
        this.hulledit_dialogVisible = true
        this.$http.post('/tp6/public/index.php/Mach/blockmarklog',{'shipno':shipno,'pname':block})
        .then((backdata) => {
            this.blockmarklog = backdata.data
            console.log(backdata.data)
          })
        this.hulledit_dialogVisible = true
      },
      handleClose (done) {
          this.$confirm('确认关闭？')
          .then(_ => {
              done()
          })
          .catch(_ => {})
          this.$emit('editclose')
      },
      submitForm(hullForm) {
        this.$refs[hullForm].validate((valid) => {
        if (valid) {
            this.$http.post('/tp6/public/index.php/Mach/blockmark', this.hullForm)
            .then((backdata) => {
                // console.log(backdata.data)
                this.hulledit_dialogVisible = false
                this.getblockinfo()
            })
        } else {
            console.log('error submit!!')
            return false
        }
        })
      },
      draw_chart(shipno) {
        var chartDom = document.getElementById(shipno)
        var myChart = echarts.init(chartDom)
        this.$http.post('/tp6/public/index.php/Mach/block_progress_chart')
            .then((backdata) => {
              this.option['xAxis']['data']=backdata.data.weekarr
              this.option['series']=backdata.data[shipno]
              // console.log(this.option['series'])
              myChart.setOption(this.option)
            })
      }
    }
  }
</script>
<style scoped>
.未标注 {
  stroke:black;
  stroke-width:1;
  fill: #dedbdb;
  fill-opacity: 0.8;
}
.分段下料 {
  stroke:black;
  stroke-width:1;
  fill: #974706;
  background-color: #974706;
}
.分段上胎 {
  stroke:black;
  stroke-width:1;
  fill: #00FF00;
  background-color:#00FF00;
}
.分段下胎 {
  stroke:black;
  stroke-width:1;
  fill: #0070C0;
  background-color:#0070C0;
}
.不锈钢安装 {
  stroke:black;
  stroke-width:1;
  fill: #FF6600;
  background-color:#FF6600;
}
.分段完工 {
  stroke:black;
  stroke-width:1;
  fill: #FF00FF;
  background-color:#FF00FF;
}
.分段抛光 {
  stroke:black;
  stroke-width:1;
  fill: #66FFCC;
  background-color:#66FFCC;
}
.分段总组 {
  stroke:black;
  stroke-width:1;
  fill: #FFC000;
  background-color:#FFC000;
}
.总组焊后 {
  stroke:black;
  stroke-width:1;
  fill: #C4BD97;
  background-color:#C4BD97;
}
.分段搭载 {
  stroke:black;
  stroke-width:1;
  fill: #FF0000;
  background-color:#FF0000;
}
.搭载焊后 {
  stroke:black;
  stroke-width:1;
  fill: #00B0F0;
  background-color:#00B0F0;
}
.货仓清洗 {
  stroke:black;
  stroke-width:1;
  fill: #00FFFF;
  background-color:#00FFFF;
}
table,tr,td,th {
    padding: 0;
    border: solid 1px;
    border-spacing: 0;
  }
</style>