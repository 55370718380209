<template>
    <div style="position:relative;width:1405px; top:0px">
        <div style="width:1405px;position: relative;">
            <svg height="520px" width="1645" style="position:relative; border:solid 1px red">
                <!-- A21 -->
                <rect id="R_A21" height="390" width="320" y="120" x="6"/>
                <text id="T_A21" font-weight="bold" font-size="24" fill="red" y="139" x="150">A21</text>
                <rect id="R_331" fill="green" height="40" width="100" y="1.42857" x="100"/>
                <text id="T_331" fill="black" y="25" x="140">331</text>
                <rect id="R_C31" height="40" width="100" y="55" x="100"/>
                <text id="T_C31" font-weight="bold" fill="red" y="70" x="142">C31</text>
                <text id="T_221_231" fill="black" y="90" x="124">221+231</text>
                <rect id="R_C11" height="50" width="120" y="144" x="20"/>
                <text id="T_C11" font-weight="bold" fill="red" y="164" x="65">C11</text>
                <text id="T_211_212" fill="black" y="182.85714" x="50">211+212</text>
                <rect id="R_D11" height="50" width="120" y="240" x="20"/>
                <text id="T_D11" font-weight="bold" fill="red" y="260" x="65">D11</text>
                <text id="T_611_612" fill="black" y="280" x="50">611+612</text>
                <rect id="R_111" height="50" width="120" y="320" x="20"/>
                <text id="T_111" fill="black" y="350" x="60">111</text>
                <rect id="R_110" height="90" width="60" y="385" x="80"/>
                <text id="T_110" fill="black" y="440" x="95">110</text>
                <rect id="R_C00" height="65" width="100" y="1" x="210"/>
                <text id="T_C00" font-weight="bold" fill="red" y="25" x="250">C00</text>
                <text id="T_222_232" fill="black" y="45" x="233.57143">222+232</text>
                <text id="T_241_251" fill="black" y="60" x="228.57143">+241+251</text>
                <rect id="R_C12" height="50" width="110" y="144.28571" x="200"/>
                <text id="T_C12" font-weight="bold" fill="red" y="164.28571" x="239.04762">C12</text>
                <text id="T_213_214" fill="black" y="182.85714" x="225">213+214</text>
                <rect id="R_D22" height="50" width="110" y="255" x="200"/>
                <text id="T_D22" font-weight="bold" fill="red" y="275" x="240">D22</text>
                <text id="T_621_622" fill="black" y="295" x="225">621+622</text>
                <rect id="R_D21" height="50" width="110" y="340" x="200"/>
                <text id="T_D21" font-weight="bold" fill="red" y="360" x="240">D21</text>
                <text id="T_623_624" fill="black" y="380" x="225">623+624</text>
                <rect id="R_B21" height="40" width="110" y="445" x="200"/>
                <text id="T_B21" font-weight="bold" fill="red" y="463" x="240">B21</text>
                <text id="T_421_422" fill="black" y="478" x="225">421+422</text>
                
                <line id="L_331_C31" y2="55" x2="148" y1="40" x1="148"/>
                <line id="L_C31_A21" y2="119" x2="120" y1="93" x1="120"/>
                <line id="L_C00_A21" y2="118" x2="260" y1="65" x1="260"/>
                <line id="L_C11_D11" y2="240" x2="80" y1="192" x1="80"/>
                <line id="L_D11_D22" y2="272" x2="200" y1="272" x1="139"/>
                <line id="L_111_D21" y2="352" x2="200" y1="352" x1="140"/>
                <line id="L_C12_D22" y2="255" x2="253" y1="191" x1="253"/>
                <line id="L_D22_D21" y2="340" x2="255" y1="304" x1="255"/>
                <line id="L_110_B21" y2="453" x2="200" y1="453" x1="139"/>
                <line id="L_D21_B21" y2="441" x2="255" y1="388" x1="255"/>

                <text id="N_D21_B21" font-weight="bold" y="421" x="256" fill="black">1</text>
                <text id="N_D21_D22" font-weight="bold" y="328" x="258" fill="black">2</text>
                <text id="N_B21_110" font-weight="bold" y="450" x="169" fill="black">3</text>
                <text id="N_D21_111" font-weight="bold" y="348" x="166" fill="black">4</text>
                <text id="N_D11_D22" font-weight="bold" y="267" x="166" fill="black">5</text>
                <text id="N_C12_D22" font-weight="bold" y="230" x="255" fill="black">6</text>
                <text id="N_C11_D11" font-weight="bold" y="222" x="82" fill="black">7</text>
                <!-- H41 -->
                <rect id="R_H41" height="390" width="333" y="118.57143" x="362"/>
                <text id="T_H41" font-size="24" font-weight="bold" fill="red" y="140.71429" x="520">H41</text>
                <rect id="R_D41" height="30" width="300" y="185" x="380"/>
                <text id="T_D41" font-weight="bold" fill="red" y="205" x="470">D41</text>
                <text id="T_631_641" fill="black" y="205" x="520">631+641</text>
                <rect id="R_S41" height="50" width="120" y="245" x="380"/>
                <text id="T_S41" font-weight="bold" fill="red" y="265" x="420">S41</text>
                <text id="T_531_541" fill="black" y="285" x="400">531+541</text>
                <rect id="R_S42" height="50" width="120" y="245" x="560"/>
                <text id="T_S42" font-weight="bold" fill="red" y="265" x="600">S42</text>
                <text id="T_532_542" fill="black" y="285" x="580">532+542</text>
                <rect id="R_B41" height="130" width="310" y="368" x="373"/>
                <text id="T_B41" font-weight="bold" fill="red" y="430" x="500">B41</text>
                <rect id="R_731" height="25" width="45" y="380" x="383"/>
                <text id="T_731" fill="black" y="398" x="396">731</text>
                <rect id="R_741" height="25" width="45" y="380" x="442"/>
                <text id="T_741" fill="black" y="398" x="455">741</text>
                <rect id="R_751" height="25" width="50" y="380" x="505"/>
                <text id="T_751" fill="black" y="398" x="508.85714">751-k1</text>
                <rect id="R_841" height="25" width="45" y="380" x="565"/>
                <text id="T_841" fill="black" y="398" x="575">841</text>
                <rect id="R_842" height="25" width="50" y="380" x="620"/>
                <text id="T_842" fill="black" y="398" x="630">842</text>
                <rect id="R_431_441_442" height="40" width="295" y="445" x="380"/>
                <text id="T_431_441_442" fill="black" y="470" x="480">431+441+442</text>

                
                <line id="L_731_431_441_442" y2="443" x2="406" y1="404" x1="406"/>
                <line id="L_741_431_441_442" y2="445" x2="466" y1="403" x1="466"/>
                <line id="L_751_431_441_442" y2="445" x2="532" y1="403" x1="532"/>
                <line id="L_841_431_441_442" y2="444" x2="585" y1="403" x1="585"/>
                <line id="L_842_431_441_442" y2="444" x2="644" y1="403" x1="644"/>
                
                <line id="L_S41_B41" y2="367" x2="438" y1="294" x1="438"/>
                <line id="L_S42_B41" y2="367" x2="618" y1="292" x1="618"/>
                <line id="L_D41_B41" y2="367" x2="529" y1="212" x1="529"/>
                <text id="N_S41_B41" font-weight="bold" y="337" x="442" fill="black">1</text>
                <text id="N_S42_B41" font-weight="bold" y="337" x="621" fill="black">2</text>
                <text id="N_D41_B41" font-weight="bold" y="302" x="531" fill="black">3</text>
                <!-- H51 -->
                <rect id="H51" height="390" width="332" y="120" x="722"/>
                <text id="H51" font-weight="bold" font-size="24" fill="red" y="139" x="870">H51</text>
                <rect id="R_361" height="20" width="45" y="144.28571" x="830"/>
                <rect id="R_362" height="20" width="45" y="145.71429" x="901"/>
                
                <text id="T_361" y="159" x="842" fill="black">361</text>
                <text id="T_362" y="160" x="912" fill="black">362</text>

                <rect id="R_D51" height="30" width="305" y="185" x="740"/>
                <text id="T_D51" font-weight="bold" fill="red" y="205" x="830">D51</text>
                <text id="T_651_661" fill="black" y="205" x="880">651+661</text>
                <rect id="R_S51" height="50" width="120" y="245" x="740"/>
                <text id="T_S51" font-weight="bold" fill="red" y="265" x="780">S51</text>
                <text id="T_551_561" fill="black" y="285" x="760">551+561</text>
                <rect id="R_S52" height="50" width="120" y="245" x="925"/>
                <text id="T_S52" font-weight="bold" fill="red" y="265" x="965">S52</text>
                <text id="T_552_562" fill="black" y="285" x="945">552+562</text>
                <rect id="R_B51" height="130" width="310" y="368" x="735"/>
                <text id="T_B51" font-weight="bold" fill="red" y="430" x="870">B51</text>
                <rect id="R_751" height="25" width="50" y="380" x="747"/>
                <text id="T_751" fill="black" y="398" x="750">751-k2</text>
                <rect id="R_761" height="25" width="45" y="380" x="815"/>
                <text id="T_761" fill="black" y="398" x="825">761</text>
                <rect id="R_851" height="25" width="45" y="380" x="925"/>
                <text id="T_851" fill="black" y="398" x="935">851</text>
                <rect id="R_861" height="25" width="45" y="380" x="990"/>
                <text id="T_861" fill="black" y="398" x="1000">861</text>
                <rect id="R_451_452_461_462" height="40" width="300" y="445" x="740"/>
                <text id="T_451_452_461_462" fill="black" y="470" x="830">451+452+461+462</text>
                
                <line id="L_S51_B51" y2="368" x2="798" y1="295" x1="798"/>
                <line id="L_S52_B51" y2="368" x2="982" y1="293" x1="982"/>
                <line id="L_D51_B51" y2="368" x2="888" y1="212" x1="888"/>
                <line id="L_D51_361" y2="184" x2="853" y1="164" x1="853"/>
                <line id="L_D51_362" y2="184" x2="922" y1="164" x1="922"/>
                <text font-weight="bold" id="N_S51_B51" y="335" x="799" fill="black">1</text>
                <text font-weight="bold" id="N_S52_B51" y="333" x="985" fill="black">2</text>
                <text font-weight="bold" id="N_D51_B51" y="292" x="890" fill="black">3</text>
                <text font-weight="bold" id="N_D51_361" y="180" x="855" fill="black">4</text>
                <text font-weight="bold" id="N_D51_361" y="180" x="923" fill="black">5</text>

                
                <line id="L751_451_452_461_462" y2="444" x2="773" y1="403" x1="773"/>
                <line id="L761_451_452_461_462" y2="444" x2="835" y1="403" x1="835"/>
                <line id="L851_451_452_461_462" y2="444" x2="948" y1="403" x1="948"/>
                <line id="L861_451_452_461_462" y2="444" x2="1012" y1="403" x1="1012"/>
                <!-- HF1 -->
                <rect id="R_HF1" height="390" width="545" y="120" x="1095"/>
                <text id="T_HF1" font-weight="bold" font-size="24" fill="red" y="139" x="1320">HF1</text>
                <rect id="R_D71" height="30" width="300" y="185" x="1135"/>
                <text id="T_D71" font-weight="bold" fill="red" y="205" x="1230">D71</text>
                <text id="T_671_681" fill="black" y="205" x="1280">671+681</text>
                <rect id="R_571" height="50" width="55" y="245" x="1122"/>
                <text id="T_571" fill="black" y="275" x="1138">571</text>
                <rect id="R_572" height="50" width="55" y="245" x="1186"/>
                <text id="T_572" fill="black" y="275" x="1200">572</text>
                <rect id="R_581" height="50" width="55" y="245" x="1318"/>
                <text id="T_581" fill="black" y="275" x="1333">581</text>
                <rect id="R_582" height="50" width="55" y="245" x="1380"/>
                <text id="T_582" fill="black" y="275" x="1395">582</text>
                <rect id="R_B71" height="130" width="150" y="368" x="1120"/>
                <text id="T_B71" font-weight="bold" fill="red" y="388" x="1180">B71</text>
                <rect id="R_771" height="30" width="120" y="395" x="1135"/>
                <text id="T_771" fill="black" y="415" x="1180">771</text>
                <rect id="R_471" height="40" width="120" y="445" x="1135"/>
                <text id="T_471" fill="black" y="470" x="1180">471</text>
                <rect id="R_B81" height="130" width="150" y="368" x="1310"/>
                <text id="T_B81" font-weight="bold" fill="red" y="388" x="1370">B81</text>
                <rect id="R_781" height="35" width="52" y="395" x="1325"/>
                <text id="T_781" fill="black" y="415" x="1335">781</text>
                <rect id="R_881" height="35" width="52" y="395" x="1388"/>
                <text id="T_881" fill="black" y="415" x="1398">881</text>
                <rect id="R_481" height="40" width="115" y="445" x="1325"/>
                <text id="T_481" fill="black" y="470" x="1365">481</text>
                <rect id="R_D91" height="160" width="120" y="135" x="1485"/>
                <text id="T_D91" font-weight="bold" fill="red" y="190" x="1530">D91</text>
                <text id="T_691_215" fill="black" y="220" x="1518.57143">691+215</text>
                <text id="T_341_364" fill="black" y="240" x="1515.47619">+341+364</text>
                <rect id="R_F91" height="145" width="100" y="330" x="1495"/>
                <text id="T_F91" font-weight="bold" fill="red" y="390" x="1530">F91</text>
                <text id="T_191_692" fill="black" y="410" x="1510">191+692</text>

                <line id="L_581_B81" y2="368" x2="1345" y1="295" x1="1345"/>
                <line id="L_582_B81" stroke-width="3" y2="368" x2="1406" y1="292" x1="1406"/>
                <line id="L_B71_B81" y2="430" x2="1309" y1="430" x1="1268"/>
                <text id="N_581_B81" font-weight="bold" y="337" x="1348" fill="black">1</text>
                <text id="N_582_B81" font-weight="bold" y="338" x="1409" fill="black">2</text>
                <text id="N_B71_B81" font-weight="bold" y="424" x="1285" fill="black">3</text>
                <line id="L_571_B71" y2="367" x2="1149" y1="292" x1="1149"/>
                <line id="L_572_B71" y2="367" x2="1210" y1="292" x1="1210"/>
                <line id="L_D71_B71" y2="368" x2="1258" y1="215" x1="1258"/>
                <text id="N_571_B71" font-weight="bold" y="335" x="1151">4</text>
                <text id="N_572_B71" font-weight="bold" y="335" x="1216">5</text>
                <text id="N_D71_B71" font-weight="bold" y="335" x="1261">6</text>
                <line id="L_B81_F91" y2="427" x2="1495" y1="427" x1="1459"/>
                <line id="L_D91_F91" y2="330" x2="1546" y1="293" x1="1546"/>
                <text font-weight="bold" id="N_B81_F91" y="421" x="1471">7</text>
                <text font-weight="bold" id="N_D91_F91" y="319" x="1548">8</text>

                <line id="L_771_471" y2="444" x2="1194" y1="423" x1="1194"/>
                <line id="L_781+481" y2="444" x2="1352" y1="430" x1="1352"/>
                <line id="L_881+481" y2="444" x2="1412" y1="428" x1="1412"/>

                <line id="L_A21_H41" y2="300" x2="361" y1="300" x1="326"/>
                <line id="L_H41_H51" y2="300" x2="723" y1="300" x1="696"/>
                <line id="L_H51_HF1" y2="300" x2="1096" y1="300" x1="1055"/>
            </svg>
        </div>
        <div>
            <form>
                <input type="color"/>
            </form>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                xf: 1,
                yf: 1,
                rt: 0.5
            }
        },
        mounted () {
        },
        methods: {
        }
    }
</script>
<style scoped>
    rect {
        fill:rgba(227, 227, 230, 0.532);
        /* fill-opacity :0; */
        stroke-width:2;
        stroke:rgb(0,0,0);
        stroke-opacity:1;
        stroke-dasharray:15,5,5,5;
    }
    line {
        /* fill:rgb(227, 227, 230); */
        /* fill-opacity :0; */
        stroke-width:3;
        stroke:rgb(77, 5, 245);
        /* stroke-opacity:5; */
    }
</style>