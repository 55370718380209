<template>
  <div >
    <table>
      <tr>
        <th width='120px'>{{ship+no+'-'+sname}}</th>
        <th v-for="(sys,syskey,sysindex) in backdata.row1" :key='sysindex' :colspan="sys.count">{{syskey}}</th>
      </tr>
      <tr v-show="pnameshow" style="background: #C1DBF5;vertical-align:top">
        <td>设备名称</td>
        <td v-for="(part,partkey,partindex) in backdata.row2" :key='partindex'
          @click="sysdrawershow=true,getpartarr('%',part.pid,'%')" style="width:15px; line-height:110%">
          <span><font size="2">{{partkey}}</font></span>
        </td>
      </tr>
      <tr v-show="pnameshow">
        <td>意见数量</td>
        <td v-for="(part,partkey,partindex) in backdata.row2" :key='partindex' style="height:20px">
          <span v-show="part['cmts']"><font size="3" color="red">{{part['cmts']}}</font></span>
        </td>
      </tr>
      <tr>
        <td>下水</td>
        <td v-for="(part,partkey,partindex) in backdata.row2" :key='partindex' style="vertical-align:top">
          <div style="width:15px; background:#C9B9AF">{{part['下水']['初检']}}</div>
          <div style="width:15px; background:yellow">{{part['下水']['复检']}}</div>
          <div style="width:15px; background:green">{{part['下水']['接受']}}</div>
        </td>
      </tr>
      <tr>
        <td>系泊</td>
        <td v-for="(part,partkey,partindex) in backdata.row2" :key='partindex' style="vertical-align:top">
          <div style="width:15px; background:#C9B9AF;">{{part['系泊']['初检']}}</div>
          <div style="width:15px; background:yellow">{{part['系泊']['复检']}}</div>
          <div style="width:15px; background:green">{{part['系泊']['接受']}}</div>
        </td>
      </tr>
      <tr>
        <td>试航</td>
        <td v-for="(part,partkey,partindex) in backdata.row2" :key='partindex' style="vertical-align:top">
          <div style="width:15px; background:#C9B9AF;">{{part['试航']['初检']}}</div>
          <div style="width:15px; background:yellow">{{part['试航']['复检']}}</div>
          <div style="width:15px; background:green">{{part['试航']['接受']}}</div>
        </td>
      </tr>
      <tr>
        <td>交船</td>
        <td v-for="(part,partkey,partindex) in backdata.row2" :key='partindex'>
          <div style="width:15px; background:#C9B9AF;">{{part['交船']['初检']}}</div>
          <div style="width:15px; background:yellow">{{part['交船']['复检']}}</div>
          <div style="width:15px; background:green">{{part['交船']['接受']}}</div>
        </td>
      </tr>
    </table>
    <!-- 抽屉显示明细 -->
    <el-drawer :visible.sync="sysdrawershow" size='80%' :withHeader="false">
      <span><font size="4">{{shipno}}</font></span>
      <!-- <el-radio-group v-model="no" @change="status='status'+no">
        <el-radio-button label="1"></el-radio-button>
        <el-radio-button label="2"></el-radio-button>
        <el-radio-button label="3"></el-radio-button>
        <el-radio-button label="4"></el-radio-button>
      </el-radio-group> -->
      <table>
        <tr>
          <th width="150px">设备</th><th width="800px">报验项目列表</th>
        </tr>
        <tr v-for="(part,pkey,pindex) in partarr" :key="pindex">
          <td>{{part.pname}}</td>
          <td style="line-height:20px">
            <div>
              <span style="color:gray;" v-if="part.下水"><b>下水</b></span>
              <span v-for="(item,ikey,iindex) in part.下水" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
              <span @click="additem(partarr[pkey])" style="float:right;color:red"><i class="el-icon-folder-add"></i></span>
            </div>
            <div>
              <span style="color:gray" v-if="part.系泊"><b>系泊</b></span>
              <span v-for="(item,ikey,iindex) in part.系泊" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
            </div>
            <div>
              <span style="color:gray" v-if="part.试航"><b>试航</b></span>
              <span v-for="(item,ikey,iindex) in part.试航" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
            </div>
            <div>
              <span style="color:gray" v-if="part.交船"><b>交船</b></span>
              <span v-for="(item,ikey,iindex) in part.交船" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
            </div>
          </td>
        </tr>
      </table>
    </el-drawer>
    <inspappdialog :appdialogvisible='appdialogvisible'  @clsinspappdlg="clsinspappdlg" @refreshstatuslist='inspApp'
     :insplist='insplist' :ship='ship'>
      <!-- <el-form-item v-show="itemdetail.id==0">
        <el-button type="primary"  @click="additem(itemdetail)">添加该报验项目</el-button>
      </el-form-item> -->
     </inspappdialog>
    <additemdialog ref="add" :ship="ship" :itemdetail="itemdetail" :additemdialogvisible='additemdialogvisible'
      @closeadditemdialog="closeAddItemDialog" @refreshlist="gettankdata(),inspApp($event,itemdetail)"></additemdialog>
  </div>
</template>
<script>
import inspappdialog from './inspAppDialog'
import additemdialog from './addItemDialog'
export default {
  components: {
    inspappdialog,
    // addpartdialog,
    additemdialog
  },
  props: [
    'shipno',
    'ship',
    'pnameshow',
    'no',
    'ppno',
    'sname'
  ],
  // components: {
  //   inspappdialog,
  //   additemdialog
  // },
  data () {
    return {
      // shipno: '3',
      // bt1: 'info',
      // bt2: 'primary',
      // pnameshow: true,
      wdh:"20",
      // ppno:3,
      // sname:"设备",
      backdata: '',
      // pnameshow: false,
      insplist: [],
      itemdetail: '',
      appdialogvisible: false,
      additemdialogvisible: false,
      sysdrawershow: false,
      partarr:'',
      getpartpostdata: { ppid: '', pid: '', stage: '' },
      status:''
    }
  },
  watch: {
    no() {
      this.gettankdata()
    }
  },
  mounted () {
    this.gettankdata()
  },
  methods: {
    async gettankdata () {
      const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/itp_status_count3', { ship:this.ship, ppno: this.ppno, no:this.no })
      this.backdata = backdata
      // console.log(backdata)
    },
    getpartarr (ppid, pid, stage) {
      this.getpartpostdata.shipno = this.shipno
      this.getpartpostdata.ship = this.ship
      this.getpartpostdata.no = this.no
      this.getpartpostdata.ppid = ppid
      this.getpartpostdata.pid = pid
      this.getpartpostdata.stage = stage
      this.status='status'+this.no
      // console.log(this.getpartpostdata)
      this.$http.post('/tp6/public/index.php/Mach/getpartarr', this.getpartpostdata)
        .then((backdata) => {
          this.partarr = backdata.data
          console.log(backdata.data)
        })
      // console.log(childdata)
    },
    inspApp (id) {
      this.$http.post('/tp6/public/index.php/Mach/id_insplist', { ship: this.ship, id: id })
        .then((backdata) => {
          this.insplist = backdata.data
          this.appdialogvisible = true
        })
    },
    clsinspappdlg () {
      this.appdialogvisible = false
      this.getpartarr (this.getpartpostdata['ppid'], this.getpartpostdata['pid'], this.getpartpostdata['stage'])
      this.gettankdata()
    },
    addpart (sys) {
      this.sysdetail = sys
      this.addpartdialogvisible = true
      // console.log(sys)
    },
    closeAddPartDialog () {
      this.addpartdialogvisible = false
    },
    inspApp2 (id) {
      // console.log(pname)
      this.$http.post('/tp6/public/index.php/Mach/id_insplist', { id: id })
        .then((backdata) => {
          this.insplist = backdata.data
          // this.itemdetail = pname
          // this.itemdetail.id = id
          // this.appdialogvisible = true
          // console.log(this.insplist)
        })
    },
    // closeInspAppDialog () {
    //   this.appdialogvisible = false
    // },
    additem (itemdetail) {
      this.itemdetail=itemdetail
      // console.log(itemdetail)
      // this.partdetail.pid = pid
      // this.partdetail.pname = pname
      this.$refs.add.setname()
      this.additemdialogvisible = true
      // console.log(itemdetail)
    },
    closeAddItemDialog () {
      this.additemdialogvisible = false
    }
  }
}
</script>

<style scoped>
  table {
    margin-top: 10px;
  }
  table,tr,td,th {
    padding: 0;
    border: solid 1px;
    border-spacing: 0;
    font-size: small;
    backdrop-filter:fixed;
  }
  tr,td,th {
    margin: 0;
    line-height:15px;
  }
  th {
    /* line-height:120%; */
    background-color: rgb(179, 191, 245);
  }
  th div{
    /* word-wrap: break-all; */
    word-wrap:break-word;
    /* overflow: hidden; */
    width: 15px;
    line-height:120%;
    /* height: 30px; */
    /* vertical-align: top; */
    /* background-color: rgb(179, 191, 245); */
  }
  .orange {
  background: #f0b6b6;
  margin:0;
  padding:0;
  border-spacing: 0;
  border:0;
  }
  .yellow {
    background: yellow;
    margin:0;
    padding:0;
    border-spacing: 0;
    border:0;
  }
  .green {
    background: rgb(154, 241, 154);
    margin:0;
    padding:0;
    border-spacing: 0;
    border:0;
  }
</style>