<template>
  <div>
    <!-- <div style="position:fixed; top:0px;height:37px;width:1200px;background:white;border:solid 2px blue;">
      <table style="font-weight:bold;">
        <tr>
          <td><div style="width:20px;height:20px;background:#C9B9AF"></div></td><td>--未报验</td><td style="width:20px"></td>
          <td><div style="width:20px;height:20px;background:yellow"></div></td><td>--复检</td><td style="width:20px"></td>
          <td><div style="width:20px;height:20px;background:green"></div></td><td>--已接受</td><td style="width:20px"></td>
          <td style="color:blue; width:250px;">注: 点击数字可查看关联报验<br><font color="red">红色:意见数量;</font><font color="b">黑色:项目数量</font></td>
          <td style="width:50px;">7200吨-</td>
          <td>
            <el-radio-group v-model="shipno" size="mini" @change="no=shipno">
              <el-radio-button label="1"></el-radio-button>
              <el-radio-button label="2"></el-radio-button>
              <el-radio-button label="3"></el-radio-button>
              <el-radio-button label="4"></el-radio-button>
            </el-radio-group>
          </td>
          <td><el-switch v-model="pnameshow" active-text="显示" inactive-text="名称隐藏"></el-switch></td>
        </tr>
      </table>
    </div> -->
    <div style="background-color: #EDEDF4;">
          <!-- <el-radio-group v-model="shipno" size="mini" @change="no=shipno,shipnosel()"> -->
          <el-radio-group v-model="shipno" size="mini" @change="shipnosel()">
              <el-radio-button label="7200-1"></el-radio-button>
              <el-radio-button label="7200-2"></el-radio-button>
              <el-radio-button label="7200-3"></el-radio-button>
              <el-radio-button label="7200-4"></el-radio-button>
              <el-radio-button label="9200-1"></el-radio-button>
              <el-radio-button label="9200-2"></el-radio-button>
              <el-radio-button label="25k-1"></el-radio-button>
              <el-radio-button label="25k-2"></el-radio-button>
          </el-radio-group>
    </div>
    <div>
      <div style="height:37px;"></div>
      <processshow :shipno="shipno" :ship="ship" :no='no' :pnameshow='pnameshow' ppno=3 sname='设备'></processshow>
      <processshow :shipno="shipno" :ship="ship" :no='no' :pnameshow='pnameshow' ppno=8 sname='电气'></processshow>
    </div>
  </div>
</template>

<script>
import processshow from '../components/itpitem/processShow'
export default {
  components: {
    processshow
  },
  data () {
    return {
      shipno: '7200-4',
      ship: '7200',
      no: '4',
      pnameshow: true
    }
  },
  mounted () {
    this.shipnosel()
  },
  methods: {
    shipnosel () {
        // alert(this.shipno.split('-')[0])
        this.ship=this.shipno.split('-')[0]
        this.no=this.shipno.split('-')[1]
        // alert(this.ship+this.no)
        // this.$http.post('/tp6/public/index.php/Mach/shipnosel', { shipno: this.shipno, ppno:this.ppno})
        // .then((backdata) => {
        //     this.shipno= backdata.data
        // })
    }
  }
}
</script>

<style scoped>
  table,td,th,tr {
    /* width:5px; */
    height:5px;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    table-layout:fixed;
    line-height:18px;
    font-size: small;
    /* border: solid 1px; */
  }
</style>