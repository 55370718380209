<template>
<!-- 使用对象: home,datatree -->
  <div>
    <div class="block">
      <span class="demonstration" style="font-size:25px; color:blue">选择日期</span>
      <el-date-picker v-model="value2" type="daterange" align="right" unlink-panels range-separator="至"
        start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions" @change="getInspList()">
      </el-date-picker>
    </div>
    <el-table :data="inspList" style="width: 100%"
      :header-cell-style="{background:'#D3DCE6',color:'#606266',height:'10px','padding':'0'}">
      <el-table-column prop="inspno" label="NO" sortable width="60"></el-table-column>
      <el-table-column prop="shipno" label="船号" sortable width="75"></el-table-column>
      <el-table-column prop="date" label="日期" sortable width="100"></el-table-column>
      <el-table-column prop="time" label="时间" width="60"></el-table-column>
      <el-table-column prop="pname" label="设备" width="150"></el-table-column>
      <el-table-column prop="name" label="项目名称" width="150"></el-table-column>
      <el-table-column prop="place" label="地点" width="80"></el-table-column>
      <el-table-column prop="qc" label="QC" width="68"></el-table-column>
      <el-table-column prop="times" label="次数" width="28"></el-table-column>
      <el-table-column prop="cls" label="船检" width="28"></el-table-column>
      <el-table-column prop="owner" label="船东" width="28"></el-table-column>
      <el-table-column prop="comments" label="意见" width="250">
        <template slot-scope="{row,}">
          <span v-for="(comment,index) in row.comments" :key="index">
            <font :color="[comment.status ? 'red':'gray']">
              {{index+1}}:{{comment.value}}
              <i class="el-icon-error" v-if="comment.status" @click="clsCmts(row.inspno,index,comment.date,comment.value,row.surveyor)"></i>
              <i class="el-icon-refresh-left" v-if="!comment.status" @click="rstCmts(row.inspno,index,comment.date,comment.value,row.surveyor)"></i>
            </font>
            <br/>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="surveyor" label="检验人" width="70"></el-table-column>
      <el-table-column prop="result" label="状态" width="80"></el-table-column>
    </el-table>
    <el-dialog title="意见编辑" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <div v-bind:itemForm="itemForm">
        <div style="font-size:20px;text-align:left;color:blue;">船号:&nbsp;&nbsp;{{itemForm.shipno}}</div>
        <div style="font-size:20px;text-align:left;color:blue;">报验单编号:&nbsp;&nbsp;{{itemForm.inspno}}</div>
        <div style="font-size:20px;text-align:left;color:blue;">项目名称:&nbsp;&nbsp;{{itemForm.name}}</div>
        <div style="font-size:20px;text-align:left;color:blue;">报验日期:&nbsp;&nbsp;{{itemForm.date}}</div>
      </div>
      <el-form :model="itemForm" ref="itemForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="报验结果" prop="inspresult"
          :rules="{ required: true, message: '报验结果不能为空', trigger: 'blur' }">
          <el-select v-model="itemForm.inspresult" placeholder="请选择报验结果">
            <el-option label="复检" value="复检"></el-option>
            <el-option label="接受" value="接受"></el-option>
            <el-option label="取消" value="取消"></el-option>
            <el-option label="拒检" value="拒检"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="(comments, index) in itemForm.comments"
          :label="'意见' + index"
          :key="comments.key"
          :prop="'comments.' + index + '.value'"
          :rules="{
            required: true, message: '意见不能为空', trigger: 'blur'
          }"
        >
          <el-input v-model="comments.value" style="width:90%"></el-input>
          <el-button @click.prevent="removeComments(comments)">删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="addComments">新增意见</el-button>
          <el-button type="primary" @click="submitForm('itemForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value1: '',
      value2: '',
      inspList: [],
      // dateRange: {
      //   start: new Date().toLocaleDateString(),
      //   end: ''
      // },
      itemForm: {
        id: '',
        shipno: '',
        inspno: '',
        name: '',
        comments: [{
          value: '',
          status: true,
          date: new Date().toLocaleDateString()
        }],
        inspresult: ''
      },
      dialogVisible: false
    }
  },
  created () {
    this.getInspList()
  },
  methods: {
    getInspList () {
      this.$http.post('/tp6/public/index.php/Mach/days_insplist', this.value2)
        .then((backdata) => {
          this.inspList = backdata.data
        })
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!')
          this.$http.post('/tp6/public/index.php/Mach/addcomments', this.itemForm)
            .then((backdata) => {
              // console.log(backdata.data)
              this.dialogVisible = false
              this.getInspList()
            })
          // console.log(this.itemForm)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    removeComments (item) {
      var index = this.itemForm.comments.indexOf(item)
      if (index !== -1) {
        this.itemForm.comments.splice(index, 1)
      }
    },
    addComments () {
      this.itemForm.comments.push({
        value: '',
        status: true,
        date: new Date().toLocaleDateString()
      })
      // console.log(this.itemForm.comments)
    },
    clsCmts (inspno, index, date, value, surveyor) {
      this.$http.post('/tp6/public/index.php/Mach/closecomments', { inspno, index, date, value, surveyor })
        .then((backdata) => {
          // console.log(backdata.data)
          this.getInspList()
        })
    },
    rstCmts (inspno, index, date, value, surveyor) {
      this.$http.post('/tp6/public/index.php/Mach/resetcomments', { inspno, index, date, value, surveyor })
        .then((backdata) => {
          // console.log(backdata.data)
          this.getInspList()
        })
    }
  }
}
</script>

<style>
  .el-table td, .el-table th.is-leaf,.el-table--border, .el-table--group{
    border-color: rgb(5, 5, 5)!important;
    border: solid 2px;
    color: black;
    /* border-bottom: 2px solid red !important; */
  }
  .el-table {
    overflow: auto !important;
  }
</style>
