<template>
    <div align="center">
      <div style="position:fixed; top:0; left:200; margin:auto">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="检验人:">
            <el-checkbox-group v-model="form.surveyor">
              <el-checkbox label="胡不为" name="surveyor"></el-checkbox>
              <el-checkbox label="刘洪新" name="surveyor"></el-checkbox>
              <el-checkbox label="李志赞" name="surveyor"></el-checkbox>
              <el-checkbox label="李谦" name="surveyor"></el-checkbox>
              <el-checkbox label="杨楼" name="surveyor"></el-checkbox>
              <el-checkbox label="魏勇" name="surveyor"></el-checkbox>
              <el-checkbox label="施丹" name="surveyor"></el-checkbox>
              <el-checkbox label="代波涛" name="surveyor"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="船号:">
            <el-checkbox-group v-model="form.shipno">
              <el-checkbox label="7200-1" name="shipno"></el-checkbox>
              <el-checkbox label="7200-2" name="shipno"></el-checkbox>
              <el-checkbox label="7200-3" name="shipno"></el-checkbox>
              <el-checkbox label="7200-4" name="shipno"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-switch v-model="clscmtshow" active-text="显示" inactive-text="关闭意见隐藏"></el-switch>
        </el-form>
      </div>
      <div style="position:absolute; top:40px; left:200; margin:auto;" >
        <el-table :data="inspdata" border style="width:auto;" :row-class-name="tableRowClassName">
          <el-table-column prop="shipno" label="船号" width="70"> </el-table-column>
          <el-table-column prop="id" label="ID" width="95"> </el-table-column>
          <el-table-column prop="pname" label="设备名称" width="200"> </el-table-column>
          <el-table-column prop="name" label="项目名称" width="220"> </el-table-column>
          <el-table-column prop="insplist" label="编号-日期-结果" width="450">
            <template slot-scope="{row,}">
              <span v-for="(id_insp,id_insp_index) in row.insplist" :key="id_insp_index">
                <div style="margin:0;padding:0">
                  <span style="color:gray;">{{'['+id_insp.inspno+']'}}</span>
                  <span :class="[id_insp.result=='接受' ? 'colorgreen':'colorblue']"><b>{{id_insp.date+' '+id_insp.result}}</b></span>
                </div>
                <span v-for="(id_comment,id_index) in id_insp.comments" :key="id_index">
                  <font v-if="id_comment.status || clscmtshow" :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}}
                  <!-- <font :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}} -->
                    <!-- 增加意见关闭人的姓名,如果与报验单同名则不显示,不同名就显示 -->
                    <span style="color:gold" v-if="id_insp.surveyor!=id_comment.closename && id_comment.closename">({{id_comment.closename+id_comment.closedate}})</span>
                    <i class="el-icon-error" v-if="id_comment.status" @click="clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                    <i class="el-icon-refresh-left" v-if="!id_comment.status" @click="rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                    <br/>
                  </font>
                </span>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="surveyor" label="检验人" width="80px"> </el-table-column>
        </el-table>
      </div>
    </div>
</template>
<script>

export default {
  data () {
    return {
      ship: '7200',
      form: {
        // surveyor: ['胡不为', '刘洪新', '李志赞','李谦','杨楼','魏勇','施丹','代波涛'],
        surveyor: [],
        // surveyor: ['李志赞','李谦'],
        shipno: ['7200-4']
      },
      inspdata: [],
      // surveyor: '杨楼',
      clscmtshow: false
    }
  },
  created () {
    this.getinspdata()
  },
  methods: {
    getinspdata () {
      this.$http.post('tp6/public/index.php/Mach/comments')
        .then((backdata) => {
          this.inspdata = backdata.data
          // console.log(this.inspdata)
        })
    },
    tableRowClassName ({ row }) {
      if (!this.form.surveyor.includes(row.surveyor) || !this.form.shipno.includes(row.shipno)) {
        return 'hidden-row'
      }
      return ''
    },
    clsCmts (inspno, index, date, value, surveyor) {
      // console.log(inspno, index, date, value, surveyor)
      // this.$http.post('/tp6/public/index.php/Mach/closecomments', { inspno, index, date, value, surveyor })
      this.$http.post('/tp6/public/index.php/Mach/closecomments', { ship:this.ship, inspno, index, date, value, surveyor })
        .then((backdata) => {
          console.log(backdata.data)
          this.getinspdata()
        })
    },
    rstCmts (inspno, index, date, value, surveyor) {
      this.$http.post('/tp6/public/index.php/Mach/resetcomments', { inspno, index, date, value, surveyor })
        .then((backdata) => {
          // console.log(backdata.data)
          this.getinspdata()
        })
    }
  }
}
</script>

<style>
  .el-table .hidden-row {
    display: none;
  }
  .el-table td{
    padding: 0px !important;
    /* border: 1px solid red!important; */
    /* color: black; */
  }
  .colorgreen {
    color:rgb(9, 175, 9);
  }
  .colorblue {
    color:blue;
  }
</style>
