<template>
    <!-- 使用对象: home,datatree -->
    <div>
        <el-dialog :visible.sync="inspedit_dialogVisible" width="60%" :before-close="handleClose">
            <h3>船号：{{shipno}}名称：{{insplist[0]['pname']}}报验项目：{{insplist[0]['name']}}</h3>
            <el-form :model="itemForm" ref="itemForm" label-width="100px" class="demo-dynamic">
                <el-row><el-col :span="6">
                <el-form-item label="日期">
                    <input type='date' v-model="itemForm.date">
                </el-form-item>
                <el-form-item label="时间" v-show="insplist[0].id!=0">
                    <el-time-select v-model="itemForm.time" :picker-options="{ start: '00:00', step: '00:30', end: '23:30' }" style="width:100px"></el-time-select>
                </el-form-item>
                </el-col><el-col :span="6">
                <el-form-item label="地点">
                    <el-select v-model="itemForm.place" placeholder="地点" style="width:120px">
                    <el-option v-for="item in place" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                </el-col><el-col :span="6">
                <el-form-item label="QC">
                    <el-select v-model="itemForm.qc" placeholder="QC" style="width:100px">
                    <el-option  v-for="item in qc" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                </el-col><el-col :span="6">
                <el-form-item label="报验结果" prop="result" 
                    :rules="{ required: true, message: '报验结果不能为空', trigger: 'blur' }">
                    <el-select v-model="itemForm.result" placeholder="请选择报验结果">
                        <el-option label="进度标注" value="进度标注"></el-option>
                        <el-option label="复检" value="复检"></el-option>
                        <el-option label="接受" value="接受"></el-option>
                        <el-option label="取消" value="取消"></el-option>
                        <el-option label="拒检" value="拒检"></el-option>
                        <el-option label="初检" value="初检"></el-option>
                    </el-select>
                </el-form-item>
                </el-col></el-row>
                <el-form-item
                    v-for="(comments, index) in itemForm.comments"
                    :label="'意见' + index"
                    :key="comments.key"
                    :prop="'comments.' + index + '.value'"
                    :rules="{
                    required: true, message: '意见不能为空', trigger: 'blur'
                    }"
                    >
                    <el-input v-model="comments.value" style="width:90%"></el-input>
                    <el-button @click.prevent="removeComments(comments)">删除</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button @click="addComments">新增意见</el-button>
                    <el-button type="primary" @click="submitForm('itemForm')">提交</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    export default {
    props: [
    'inspedit_dialogVisible',
    'insplist',
    'shipno',
    'ship'
    ],
    data () {
    return {
        place: [
            { value: '厂家', label: '厂家' },
            { value: '分段车间', label: '分段车间' },
            { value: '分段堆场', label: '分段堆场' },
            { value: '管车间', label: '管车间' },
            { value: '船台', label: '船台' },
            { value: '码头', label: '码头' },
            { value: '试航', label: '试航' }
        ],
        qc: [
            { value: '熊峰', label: '熊峰' },
            { value: '闫小健', label: '闫小健' },
            { value: '钱凯', label: '钱凯' },
            { value: '宋刚', label: '宋刚' }
        ],
        itemForm: {
            date: this.getNowFormatDate(),
            time: '09:30',
            place: '分段堆场',
            shipno: '7200-3',
            qc: '钱凯',
            id: '',
            pname: '',
            name: '',
            status: '',
            comments: [{
                value: '',
                status: true,
                date: new Date().toLocaleDateString()
            }],
            result: ''
        },
    }
    },
    // created () {
    // this.getInspList()
    // },
    methods: {
    // getInspList () {
    //     this.$http.post('/tp6/public/index.php/Mach/day_insplist', { date: this.value1.toLocaleDateString() })
    //     .then((backdata) => {
    //         this.inspList = backdata.data
    //         // console.log(this.inspList)
    //     })
    // },
    // editInsp (item) {
    //     this.itemForm.id = item.id
    //     this.itemForm.name = item.name
    //     this.itemForm.date = item.date
    //     this.itemForm.shipno = item.shipno
    //     this.itemForm.inspno = item.inspno
    //     this.dialogVisible = true
    // },
    delInsp (row) {
        if (row.result) {
        this.$message.error('存在结果,不能删除!')
        } else {
        this.$http.post('/tp6/public/index.php/Mach/delinsp', row)
            .then((backdata) => {
            this.$message.error(backdata.data)
            this.getInspList()
            })
        }
    },
    handleClose (done) {
        this.$confirm('确认关闭？')
        .then(_ => {
            done()
        })
        .catch(_ => {})
        this.$emit('editclose')
    },
    submitForm (formName) {
        this.itemForm.shipno=this.shipno
        this.itemForm.id=this.insplist[0]['id']
        this.itemForm.pname=this.insplist[0]['pname']
        this.itemForm.name=this.insplist[0]['name']
        // this.itemForm.stage=this.insplist[0]['stage']
        this.itemForm.status=this.insplist[0]['status']
        // console.log(this.itemForm)
        this.$refs[formName].validate((valid) => {
        if (valid) {
            this.$http.post('/tp6/public/index.php/Mach/addcomments', this.itemForm)
            .then((backdata) => {
                console.log(backdata.data)
                this.dialogVisible = false
                this.getInspList()
            })
        } else {
            console.log('error submit!!')
            return false
        }
        })
        this.$emit('editclose')
    },
    removeComments (item) {
        var index = this.itemForm.comments.indexOf(item)
        if (index !== -1) {
        this.itemForm.comments.splice(index, 1)
        }
    },
    addComments () {
        this.itemForm.comments.push({
        value: '',
        status: true,
        date: new Date().toLocaleDateString()
        })
        // console.log(this.itemForm.comments)
    },
    clsCmts (inspno, index, date, value, surveyor) {
        console.log(surveyor)
        this.$http.post('/tp6/public/index.php/Mach/closecomments', {ship:this.ship, inspno, index, date, value, surveyor })
        .then((backdata) => {
            // console.log(backdata.data)
            this.getInspList()
        })
    },
    rstCmts (inspno, index, date, value, surveyor) {
        this.$http.post('/tp6/public/index.php/Mach/resetcomments', {ship:this.ship, inspno, index, date, value, surveyor })
        .then((backdata) => {
            // console.log(backdata.data)
            this.getInspList()
        })
    }
    }
    }
</script>

<style scoped>

</style>