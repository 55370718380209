<template>
  <div>
    <table><!-- 选项卡, 用于选择大的分类及船号 -->
      <tr height="20px">
        <td width="400px"  style="border:0">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick" style="height:40px;">
            <el-tab-pane label="分段" name="1"></el-tab-pane>
            <el-tab-pane label="合拢" name="7"></el-tab-pane>
            <el-tab-pane label="管件" name="4"></el-tab-pane>
            <el-tab-pane label="管系" name="2"></el-tab-pane>
            <el-tab-pane label="舱室" name="5"></el-tab-pane>
            <el-tab-pane label="货舱" name="6"></el-tab-pane>
            <el-tab-pane label="设备" name="3"></el-tab-pane>
            <el-tab-pane label="电气" name="8"></el-tab-pane>
          </el-tabs>
        </td>
        <td style="border:0">
        </td>
        <td style="color:red;padding-left:20px;border:0">
          注:申请:点击系统或设备名称-->点击报验项目
        </td>
      </tr>
    </table>

    <table cellspacing=0 style="left:150px;top:0;overflow:scroll;"><!-- 显示系统设备列表及分阶段数字 -->
      <tr>
        <th width="120px">系统名称</th><th width="600px">设备名称</th>
      </tr>
      <tr v-for="(sys,key,index) in sysarr" :key="index">
        <!-- 显示系统名称 -->
        <td style="color:blue;width:120px" @click="sysdrawer=true,getpartarr(sys.ppid,'%','%')">
          <el-link type="primary">
            <b>{{sys.ppname}}</b>
          </el-link>
        </td>
        <!-- 显示设备名称 -->
        <td style="text-align:left;">
          <span v-for="(part,pid,pindex) in sys.partlist" :key="pindex" style="margin:5px;color:blue;float:left"
           @click="sysdrawer = true,getpartarr('%',pid,'%')">&nbsp;<nobr>{{part}}</nobr></span>
           <span style="margin:5px;color:red" @click="addpart(sys)"><i class="el-icon-folder-add"></i></span>
        </td>
      </tr>
    </table>
    <!-- 抽屉显示明细 -->
    <el-drawer :visible.sync="sysdrawer" size='80%' :withHeader="false">
      <span><font size="4">请选择船号7200-</font></span>
      <el-radio-group v-model="no" @change="status='status'+no">
        <el-radio-button label="1"></el-radio-button>
        <el-radio-button label="2"></el-radio-button>
        <el-radio-button label="3"></el-radio-button>
        <el-radio-button label="4"></el-radio-button>
      </el-radio-group>
      <table>
        <tr>
          <th width="150px">设备</th><th width="800px">报验项目列表</th>
        </tr>
        <tr v-for="(part,pkey,pindex) in partarr" :key="pindex">
          <td>{{part.pname}}</td>
          <td>
            <div>
              <span style="color:gray;" v-if="part.下水"><b>下水</b></span>
              <span v-for="(item,ikey,iindex) in part.下水" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
              <span @click="additem(partarr[pkey])" style="float:right;color:red"><i class="el-icon-folder-add"></i></span>
            </div>
            <div>
              <span style="color:gray" v-if="part.系泊"><b>系泊</b></span>
              <span v-for="(item,ikey,iindex) in part.系泊" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
            </div>
            <div>
              <span style="color:gray" v-if="part.试航"><b>试航</b></span>
              <span v-for="(item,ikey,iindex) in part.试航" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
            </div>
            <div>
              <span style="color:gray" v-if="part.交船"><b>交船</b></span>
              <span v-for="(item,ikey,iindex) in part.交船" :key="iindex" style="margin:5px; word-break:keep-all;"
                :class="[item[status]=='初检' ? 'orange':'',item[status]=='复检' ? 'yellow':'',item[status]=='接受' ? 'green':'']"
                @click="inspApp(item.id)">
                <el-link v-show="item[status]"><span>{{item.name}}</span></el-link>
              </span>
            </div>
          </td>
        </tr>
      </table>
    </el-drawer>
    <inspappdialog :appdialogvisible='appdialogvisible'  @clsinspappdlg="closeInspAppDialog" @refreshstatuslist='inspApp'
     :insplist='insplist' :itemdetail="itemdetail"></inspappdialog>
    <addpartdialog :sysdetail="sysdetail" :addpartdialogvisible='addpartdialogvisible'
     @closeaddpartdialog="closeAddPartDialog" @refreshlist="handleClick"></addpartdialog>
    <additemdialog :itemdetail="itemdetail" :additemdialogvisible='additemdialogvisible' @closeadditemdialog="closeAddItemDialog"
     @refreshlist="getpartarr(getpartpostdata.ppid,getpartpostdata.pid,getpartpostdata.stage,$event)"></additemdialog>
  </div>
</template>
<script>
  import inspappdialog from './inspAppDialog'
  import addpartdialog from './addPartDialog'
  import additemdialog from './addItemDialog'
  export default {
    components: {
      inspappdialog,
      addpartdialog,
      additemdialog
    },
    data () {
      return {
        no: '3',
        // btn1: 'info',
        // btn2: 'primary',
        itemshow: false,
        // show2: true,
        // show1: false,
        status: 'status3',
        activeName: '5',
        sysarr: [],
        times: 4,
        partarr: '',
        getpartpostdata: { ppid: '', pid: '', stage: '' },
        sysdrawer: false,
        partdrawer: false,
        direction: 'ltr',
        // itemrow: '',
        insplist: [],
        appdialogvisible: false,
        // sysdetail为要添加的part的sys信息
        sysdetail: '',
        addpartdialogvisible: false,
        // itemdetail为要添加的part的sys信息
        itemdetail: [],
        additemdialogvisible: false
      }
    },
    mounted () {
      this.handleClick()
    },
    methods: {
      handleClick () {
        this.$http.post('/tp6/public/index.php/Mach/getsysarr', { code: this.activeName })
          .then((backdata) => {
            this.sysarr = backdata.data
          })
      },
      getpartarr (ppid, pid, stage, childdata) {
        this.getpartpostdata.ppid = ppid
        this.getpartpostdata.pid = pid
        this.getpartpostdata.stage = stage
        // console.log(this.getpartpostdata)
        this.$http.post('/tp6/public/index.php/Mach/getpartarr', this.getpartpostdata)
          .then((backdata) => {
            this.partarr = backdata.data
            console.log(backdata.data)
          })
        // console.log(childdata)
      },
      inspApp (id) {
        this.$http.post('/tp6/public/index.php/Mach/id_insplist', { id: id })
          .then((backdata) => {
            this.insplist = backdata.data
            this.appdialogvisible = true
          })
      },
      closeInspAppDialog () {
        this.appdialogvisible = false
      },
      addpart (sys) {
        this.sysdetail = sys
        this.addpartdialogvisible = true
        // console.log(sys)
      },
      closeAddPartDialog () {
        this.addpartdialogvisible = false
      },
      additem (part) {
        this.itemdetail = part
        this.additemdialogvisible = true
        // console.log(this.itemdetail)
      },
      closeAddItemDialog () {
        this.additemdialogvisible = false
      }
    }
  }
</script>

<style scoped>
  tr,th,td{
    border: solid 1px blue;
  }
  td{
    /* background: rgb(232, 233, 231); */
    height:20px;
  }
  th{
    background: rgb(91, 181, 241);
  }
  .row-style {
    height: 10px;
  }
  .orange {
  background: #f0b6b6;
  margin:0;
  padding:0;
  border-spacing: 0;
  border:0;
  }
  .yellow {
    background: yellow;
    margin:0;
    padding:0;
    border-spacing: 0;
    border:0;
  }
  .green {
    background: rgb(154, 241, 154);
    margin:0;
    padding:0;
    border-spacing: 0;
    border:0;
  }
  .itemdiv {
    height: 15px;
    width: 15px;
    display: inline-block;
    border: 1px solid;
  }
</style>
