<template>
  <div>
    <el-dialog :title="sysdetail.ppname" :visible.sync="addpartdialogvisible" :before-close="handleClose" width="60%">
      <el-form :inline="true" :model="newpart" class="demo-form-inline" style="text-align:left">
        <b>新的设备名称:</b><el-input v-model="newpart.name" style="width:50%;margin:30px"></el-input>
        <el-button type="primary"  @click="addpart()">添加</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: [
    'sysdetail',
    'addpartdialogvisible'
  ],
  data () {
    return {
      newpart: {
        pid: '',
        pname: '',
        name: ''
      }
    }
  },
  created () {
  },
  methods: {
    handleClose () {
      this.$emit('closeaddpartdialog')
    },
    addpart () {
      this.newpart.pid = this.sysdetail.ppid
      this.newpart.pname = this.sysdetail.ppname
      // console.log(this.newpart)
      this.$http.post('tp6/public/index.php/Mach/addpart', this.newpart)
        .then((backdata) => {
          if (backdata.data === 1) {
            this.$message.error('添加失败')
          }
          this.$message.success('成功添加了' + backdata.data + '条数据')
          this.handleClose()
          this.$emit('refreshlist')
          // console.log(backdata.data)
        })
      // console.log(this.newpart)
    }
  }
}
</script>

<style lang='less' scoped>

</style>
