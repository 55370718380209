<template>
<!-- 使用对象: home,datatree -->
  <div>
    <div style="background-color: #EDEDF4;">
      <!-- <el-radio-group v-model="shipno" size="mini" @change="no=shipno,shipnosel()"> -->
      <el-radio-group v-model="ship" size="mini" @change="getInspList()">
          <el-radio-button label="7200"></el-radio-button>
          <el-radio-button label="9200"></el-radio-button>
          <el-radio-button label="25k"></el-radio-button>
      </el-radio-group>
    <!-- </div>
    <div class="block"> -->
    
    <span class="demonstration">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 选择日期</span>
    <el-date-picker v-model="value1" type="date" placeholder="选择日期"  @change="getInspList"> </el-date-picker>
  </div>
    <el-table :data="inspList" style="width: 100%"
      :header-cell-style="{background:'#D3DCE6',color:'#606266',height:'10px','padding':'0'}">
      <el-table-column prop="shipno" label="船号" sortable width="75"></el-table-column>
      <el-table-column prop="pname" label="设备" width="150"></el-table-column>
      <el-table-column prop="name" label="项目名称" width="200"></el-table-column>
      <el-table-column prop="place" label="地点" width="80"></el-table-column>
      <el-table-column prop="qc" label="QC" width="68"></el-table-column>
      <el-table-column prop="times" label="次数" width="28"></el-table-column>
      <el-table-column prop="cls" label="船检" width="28"></el-table-column>
      <el-table-column prop="owner" label="船东" width="28"></el-table-column>
      <el-table-column prop="comments" label="编号-检验人-日期-结果-意见" width="350">
        <template slot-scope="{row,}">
          <span v-for="(id_insp,id_insp_index) in row.comments" :key="id_insp_index">
            <div>
              <span style="color:gray;">{{'['+id_insp.inspno+']'}}</span>
              <span style="color:blue;"><b>{{id_insp.surveyor+' '+id_insp.date+' '+id_insp.time+' '+id_insp.result}}</b></span>
            </div>
            <span v-for="(id_comment,id_index) in id_insp.comments" :key="id_index">
              <font :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}}
                <!-- 增加意见关闭人的姓名,如果与报验单同名则不显示,不同名就显示 -->
                <span style="color:gold" v-if="id_insp.surveyor!=id_comment.closename && id_comment.closename">({{id_comment.closename+id_comment.closedate}})</span>
                <i class="el-icon-error" v-if="id_comment.status" @click="clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                <i class="el-icon-refresh-left" v-if="!id_comment.status" @click="rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
              </font><br/>
            </span>
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="surveyor" label="检验人" width="70"></el-table-column> -->
      <el-table-column label="编辑" width="90">
        <template slot-scope="scope">
          <i class="el-icon-edit" style="font-size:20px; color:#AF6C08; margin:0 5px" @click="editInsp(scope.row)"></i>
          <i class="el-icon-delete" style="font-size:20px; color: red; margin:0 5px" @click="delInsp(scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <div v-bind:itemForm="itemForm">
        <div style="font-size:15px;text-align:left;color:blue;">编号:&nbsp;&nbsp;{{itemForm.inspno}}</div>
        <div style="font-size:15px;text-align:left;color:blue;">船号:&nbsp;&nbsp;{{itemForm.shipno}}</div>
        <div style="font-size:15px;text-align:left;color:blue;">名称:&nbsp;&nbsp;{{itemForm.name}}</div>
        <div style="font-size:15px;text-align:left;color:blue;">日期:&nbsp;&nbsp;{{itemForm.date}}</div>
      </div>
      <el-form :model="itemForm" ref="itemForm" label-width="100px" class="demo-dynamic">
        <el-form-item label="报验结果" prop="result"
          :rules="{ required: true, message: '报验结果不能为空', trigger: 'blur' }">
          <el-select v-model="itemForm.result" placeholder="请选择报验结果">
            <el-option label="复检" value="复检"></el-option>
            <el-option label="接受" value="接受"></el-option>
            <el-option label="取消" value="取消"></el-option>
            <el-option label="拒检" value="拒检"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="(comments, index) in itemForm.comments"
          :label="'意见' + index"
          :key="comments.key"
          :prop="'comments.' + index + '.value'"
          :rules="{
            required: true, message: '意见不能为空', trigger: 'blur'
          }"
        >
          <el-input v-model="comments.value" style="width:90%"></el-input>
          <el-button @click.prevent="removeComments(comments)">删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="addComments">新增意见</el-button>
          <el-button type="primary" @click="submitForm('itemForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        ship: '7200',
        value1: new Date(),
        inspList: [],
        itemForm: {
          id: '',
          shipno: '',
          inspno: '',
          name: '',
          comments: [{
            value: '',
            status: true,
            date: new Date().toLocaleDateString()
          }],
          result: ''
        },
        dialogVisible: false
      }
    },
    created () {
      this.getInspList()
    },
    methods: {
      getInspList () {
        this.$http.post('/tp6/public/index.php/Mach/day_insplist', { date: this.value1.toLocaleDateString() ,ship: this.ship})
          .then((backdata) => {
            this.inspList = backdata.data
          })
      },
      editInsp (item) {
        this.itemForm.id = item.id
        this.itemForm.name = item.name
        this.itemForm.date = item.date
        this.itemForm.shipno = item.shipno
        this.itemForm.inspno = item.inspno
        this.dialogVisible = true
      },
      delInsp (row) {
        this.$http.post('/tp6/public/index.php/Mach/delinsp', row)
          .then((backdata) => {
            this.$message.error(backdata.data)
            this.getInspList()
          })
      },
      handleClose (done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {})
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post('/tp6/public/index.php/Mach/addcomments', this.itemForm)
              .then((backdata) => {
                console.log(backdata.data)
                this.dialogVisible = false
                this.getInspList()
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      removeComments (item) {
        var index = this.itemForm.comments.indexOf(item)
        if (index !== -1) {
          this.itemForm.comments.splice(index, 1)
        }
      },
      addComments () {
        this.itemForm.comments.push({
          value: '',
          status: true,
          date: new Date().toLocaleDateString()
        })
      },
      clsCmts (inspno, index, date, value, surveyor) {
        // console.log(surveyor)
        this.$http.post('/tp6/public/index.php/Mach/closecomments', { ship:this.ship, inspno, index, date, value, surveyor })
          .then((backdata) => {
            this.getInspList()
            // this.$emit('refreshstatuslist', this.insplist[0].id)
          })
      },
      rstCmts (inspno, index, date, value, surveyor) {
        // console.log(surveyor)
        this.$http.post('/tp6/public/index.php/Mach/resetcomments', { ship:this.ship, inspno, index, date, value, surveyor })
          .then((backdata) => {
            this.getInspList()
            // this.$emit('refreshstatuslist', this.insplist[0].id)
          })
      }
    }
  }
</script>

<style lang='less' scoped>

</style>
