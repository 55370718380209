<template>
  <!-- <el-container style="height:100vh;"> -->
    <!--顶栏-->
    <!-- <el-header height="30px">
      <div style="background-color: #EDEDF4;">
          <el-radio-group v-model="shipno" size="mini" @change="no=shipno,shipnosel()">
              <el-radio-button label="7200-1"></el-radio-button>
              <el-radio-button label="7200-2"></el-radio-button>
              <el-radio-button label="7200-3"></el-radio-button>
              <el-radio-button label="7200-4"></el-radio-button>
              <el-radio-button label="9200-1"></el-radio-button>
              <el-radio-button label="9200-2"></el-radio-button>
              <el-radio-button label="25k-1"></el-radio-button>
              <el-radio-button label="25k-2"></el-radio-button>
          </el-radio-group>
      </div>
    </el-header> -->
    <el-container style="height:100%">
      <!-- 侧边栏 -->
      <el-aside width="150px">
        <span v-bind="user">{{user.name}}您好!<button @click="logout">退出</button></span>
        <el-menu unique-opened :router='true' default-active="/hull">
          <el-menu-item index="/hull"><i class="el-icon-s-data"></i>分段进度</el-menu-item>
          <el-menu-item index="/block_erect"><i class="el-icon-s-data"></i>重组搭载</el-menu-item>
          <!-- <el-menu-item index="/overview"><i class="el-icon-s-data"></i>分段制造</el-menu-item> -->
          <el-menu-item index="/overview"><i class="el-icon-s-data"></i>分段制造</el-menu-item>
          <el-menu-item index="/overview2"><i class="el-icon-s-data"></i>舱室管系</el-menu-item>
          <el-menu-item index="/overview3"><i class="el-icon-s-data"></i>机电统计</el-menu-item>
          <el-menu-item index="/dayInsplist"><i class="el-icon-date"></i>今日报验</el-menu-item>
          <el-menu-item index="/daysInsplist"><i class="el-icon-tickets"></i>报验列表</el-menu-item>
          <el-menu-item index="/itpmanage"><i class="el-icon-edit-outline"></i>项目添加</el-menu-item>
          <el-menu-item index="/comments"><i class="el-icon-notebook-2"></i>意见统计</el-menu-item>
          <el-menu-item index="/ncrlist"><i class="el-icon-notebook-1"></i>巡检意见</el-menu-item>
          <el-menu-item index="/weekreport"><i class="el-icon-postcard"></i>周报查看</el-menu-item>
          <el-menu-item index="/test"><i class="el-icon-s-unfold"></i>报检进度</el-menu-item>
          <!-- <el-menu-item index="/dwg"><i class="el-icon-s-unfold"></i>图纸审核</el-menu-item> -->
          <el-menu-item index="/user" v-if="user.name=='刘洪新'"><i class="el-icon-user-solid"></i>用户管理</el-menu-item>
          <!-- <el-menu-item index="/processShow" v-if="user.name=='刘洪新'"><i class="el-icon-s-data"></i>测试</el-menu-item> -->
        </el-menu>
        <div style="color:red;" align="left"><strong>声明:</strong><br>本网站所有内容仅供参考,不作为任何依据或凭证。</div>
      </el-aside>
      <el-container>
        <!-- 头部区域 -->
        <!-- <el-header>
          <shipnoselect></shipnoselect>
        </el-header> -->
        <!-- 主内容区 -->
        <el-main>
          <!-- 路由占位符 -->
          <router-view  :username='user.name' :shipno='shipno'></router-view>
        </el-main>
        <el-footer height="30px">
          <div class="div4"><a href="https://beian.miit.gov.cn/">沪ICP备19019562号</a></div>
        </el-footer>
      </el-container>
    </el-container>
  <!-- </el-container> -->
</template>

<script>
// import shipnoselect from '../components/itpitem/shipnoselect.vue'
  // @ is an alias to /src
  // import HelloWorld from '@/components/HelloWorld.vue'
  export default {
  components:
    {
      //  shipnoselect 
    },
    // name: 'Home',
    // components: {
    //   HelloWorld,
    // },
    data () {
      return {
        no: '7200-3',
        shipno: '7200-3',
        user: {
          name: '',
          loginresult: ''
        }
      }
    },
    created () {
      this.login()
    },
    methods: {
      async login () {
        const { data: backdata } = await this.$http.post('/tp6/public/index.php/Login/iflogin')
        if (backdata.loginresult === 'loginfail') {
          this.$router.push({ name: 'login' })
        } else {
          this.user.name = backdata.name
        }
      },
      logout () {
        this.$http.post('/tp6/public/index.php/Login/logout')
        this.$router.push({ name: 'login' })
      },
      shipnosel () {
        // alert(this.shipno)
        this.$http.post('/tp6/public/index.php/Mach/shipnosel', { shipno: this.shipno })
        .then((backdata) => {
          this.shipno= backdata.data
          // this.itemdetail = pname
          // this.itemdetail.id = id
          // this.appdialogvisible = true
          // alert(backdata.data)
        })
      }
    }
  }
</script>
<style scoped>
  /* .el-container{
    height:100%;
  } */
  .el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 30px;
  }
  .el-main {
    /* background-color: #d3dce6; */
    color: #333;
    text-align: center;
    line-height: 30px;
    padding: 0px 10px;
    /* height:100%; */
  }
  .el-footer {
    background-color: #F6F6F6;
    color: #333;
    text-align: center;
    line-height: 30px;
  }
  .el-menu-item {
    height: 45px;
    line-height: 50px;
    font-size: 17px;
    background-color: rgb(237, 237, 244);
  }
</style>
