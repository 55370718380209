<template>
  <div>
    <el-dialog :title="itemdetail.pname" :visible.sync="additemdialogvisible" :before-close="handleClose" width="60%">
      <el-form :inline="true" :model="inspitem" class="demo-form-inline">
        <el-form-item label="船号">
          <el-checkbox-group v-model="inspitem.status" style="width:200px;display:block">
            <el-checkbox :label="ship+'-1'" :checked="true" ></el-checkbox>
            <el-checkbox :label="ship+'-2'" :checked="true" ></el-checkbox>
            <el-checkbox :label="ship+'-3'" :checked="true" ></el-checkbox>
            <el-checkbox :label="ship+'-4'" :checked="true" ></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="阶段">
          <el-select v-model="inspitem.stage" placeholder="阶段" style="width:100px">
            <el-option v-for="item in stage" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="船厂">
          <el-select v-model="inspitem.shipyard" placeholder="船厂" style="width:60px">
            <el-option v-for="item in shipyard" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="船检">
          <el-select v-model="inspitem.cls" placeholder="船检" style="width:60px">
            <el-option v-for="item in cls" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="船东">
          <el-select v-model="inspitem.owner" placeholder="船东" style="width:60px">
            <el-option  v-for="item in owner" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="专业">
          <el-select v-model="inspitem.profession" placeholder="专业" style="width:60px">
            <el-option  v-for="item in profession" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-input v-model="inspitem.name" placeholder="请输入新的设备或项目名称"  style="width:600px;margin:0"></el-input>
        <el-button type="primary"  @click="addinspitem()">添加</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: [
    'ship',
    'itemdetail',
    'additemdialogvisible'
  ],
  data () {
    return {
      inspitem: {
        ship: '',
        pid: '',
        pname: '',
        name: '',
        stage: '下水',
        shipyard: 'Y',
        cls: 'Y',
        owner: 'Y',
        profession: 'H',
        status: []
      },
      // shipno: [
      //   { value: '7200-1', label: '7200-1' },
      //   { value: '7200-2', label: '7200-2' },
      //   { value: '7200-3', label: '7200-3' },
      //   { value: '7200-4', label: '7200-4' }
      // ],
      stage: [
        { value: '下水', label: '下水' },
        { value: '系泊', label: '系泊' },
        { value: '试航', label: '试航' },
        { value: '交船', label: '交船' }
      ],
      owner: [
        { value: 'Y', label: 'Y' },
        { value: 'N', label: 'N' }
      ],
      cls: [
        { value: 'Y', label: 'Y' },
        { value: 'N', label: 'N' }
      ],
      shipyard: [
        { value: 'Y', label: 'Y' },
        { value: 'N', label: 'N' }
      ],
      profession: [
        { value: 'M', label: 'M' },
        { value: 'E', label: 'E' },
        { value: 'H', label: 'H' },
        { value: 'P', label: 'P' }
      ]
    }
  },
  mounted () {
    this.setname()
  },
  methods: {
    addinspitem () {
      // alert(this.ship)
      this.inspitem.ship = this.ship
      this.inspitem.pid = this.itemdetail.pid
      this.inspitem.pname = this.itemdetail.pname
      console.log(this.inspitem)
      this.$http.post('tp6/public/index.php/Mach/additem', this.inspitem)
        .then((backdata) => {
          console.log(backdata.data)
          if (backdata.data === 0) {
            this.$message.error('添加失败')
          } else {
            this.$message.success('成功添加了' + backdata.data)
            this.handleClose()
            this.$emit('refreshlist', backdata.data)
          }
        })
    },
    handleClose () {
      this.$emit('closeadditemdialog')
    },
    setname () {
      this.inspitem.name = this.itemdetail.name
    }
  }
}
</script>
<style scoped>

</style>
