var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"visible":_vm.appdialogvisible,"before-close":_vm.handleClose,"width":"98%"},on:{"update:visible":function($event){_vm.appdialogvisible=$event}}},[_vm._t("default"),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.insplist,"cell-style":{'border':'solid 1px'},"header-cell-style":{'border':'solid 2px'}}},[_c('el-table-column',{attrs:{"prop":"id","label":"id","sortable":"","width":"95"}}),_c('el-table-column',{attrs:{"prop":"pname","label":"设备名称","width":"150"}}),_c('el-table-column',{attrs:{"prop":"name","label":"名称","width":"150"}}),_c('el-table-column',{attrs:{"prop":"stage","label":"阶段","width":"55"}}),_c('el-table-column',{attrs:{"prop":"profession","label":"专业","width":"55"}}),_c('el-table-column',{attrs:{"label":_vm.ship+'-1编号/日期/结果/意见',"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._l((row.comments1),function(id_insp,id_insp_index){return _c('span',{key:id_insp_index},[_c('div',[_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s('['+id_insp.inspno+']'))]),_c('span',{staticStyle:{"color":"blue"}},[_c('b',[_vm._v(_vm._s(id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result))])])]),_vm._l((id_insp.comments),function(id_comment,id_index){return _c('span',{key:id_index},[_c('font',{attrs:{"color":[id_comment.status ? 'red':'gray']}},[_vm._v(_vm._s(id_index+1)+":"+_vm._s(id_comment.value)+" "),(id_insp.surveyor!=id_comment.closename && id_comment.closename)?_c('span',{staticStyle:{"color":"gold"}},[_vm._v("("+_vm._s(id_comment.closename+id_comment.closedate)+")")]):_vm._e(),(id_comment.status)?_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e(),(!id_comment.status)?_c('i',{staticClass:"el-icon-refresh-left",on:{"click":function($event){return _vm.rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e()]),_c('br')],1)})],2)}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.insplist[0].id!=0),expression:"insplist[0].id!=0"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.inspedit(_vm.ship+'-1')}}},[_vm._v("添加记录")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.ship+'-2编号/日期/结果/意见',"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._l((row.comments2),function(id_insp,id_insp_index){return _c('span',{key:id_insp_index},[_c('div',[_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s('['+id_insp.inspno+']'))]),_c('span',{staticStyle:{"color":"blue"}},[_c('b',[_vm._v(_vm._s(id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result))])])]),_vm._l((id_insp.comments),function(id_comment,id_index){return _c('span',{key:id_index},[_c('font',{attrs:{"color":[id_comment.status ? 'red':'gray']}},[_vm._v(_vm._s(id_index+1)+":"+_vm._s(id_comment.value)+" "),(id_insp.surveyor!=id_comment.closename && id_comment.closename)?_c('span',{staticStyle:{"color":"gold"}},[_vm._v("("+_vm._s(id_comment.closename+id_comment.closedate)+")")]):_vm._e(),(id_comment.status)?_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e(),(!id_comment.status)?_c('i',{staticClass:"el-icon-refresh-left",on:{"click":function($event){return _vm.rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e()]),_c('br')],1)})],2)}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.insplist[0].id!=0),expression:"insplist[0].id!=0"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.inspedit(_vm.ship+'-2')}}},[_vm._v("添加记录")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.ship+'-3编号/日期/结果/意见',"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._l((row.comments3),function(id_insp,id_insp_index){return _c('span',{key:id_insp_index},[_c('div',[_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s('['+id_insp.inspno+']'))]),_c('span',{staticStyle:{"color":"blue"}},[_c('b',[_vm._v(_vm._s(id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result))])])]),_vm._l((id_insp.comments),function(id_comment,id_index){return _c('span',{key:id_index},[_c('font',{attrs:{"color":[id_comment.status ? 'red':'gray']}},[_vm._v(_vm._s(id_index+1)+":"+_vm._s(id_comment.value)+" "),(id_insp.surveyor!=id_comment.closename && id_comment.closename)?_c('span',{staticStyle:{"color":"gold"}},[_vm._v("("+_vm._s(id_comment.closename+id_comment.closedate)+")")]):_vm._e(),(id_comment.status)?_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e(),(!id_comment.status)?_c('i',{staticClass:"el-icon-refresh-left",on:{"click":function($event){return _vm.rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e()]),_c('br')],1)})],2)}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.insplist[0].id!=0),expression:"insplist[0].id!=0"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.inspedit(_vm.ship+'-3')}}},[_vm._v("添加记录")])]}}])}),_c('el-table-column',{attrs:{"label":_vm.ship+'-4编号/日期/结果/意见',"width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._l((row.comments4),function(id_insp,id_insp_index){return _c('span',{key:id_insp_index},[_c('div',[_c('span',{staticStyle:{"color":"gray"}},[_vm._v(_vm._s('['+id_insp.inspno+']'))]),_c('span',{staticStyle:{"color":"blue"}},[_c('b',[_vm._v(_vm._s(id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result))])])]),_vm._l((id_insp.comments),function(id_comment,id_index){return _c('span',{key:id_index},[_c('font',{attrs:{"color":[id_comment.status ? 'red':'gray']}},[_vm._v(_vm._s(id_index+1)+":"+_vm._s(id_comment.value)+" "),(id_insp.surveyor!=id_comment.closename && id_comment.closename)?_c('span',{staticStyle:{"color":"gold"}},[_vm._v("("+_vm._s(id_comment.closename+id_comment.closedate)+")")]):_vm._e(),(id_comment.status)?_c('i',{staticClass:"el-icon-error",on:{"click":function($event){return _vm.clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e(),(!id_comment.status)?_c('i',{staticClass:"el-icon-refresh-left",on:{"click":function($event){return _vm.rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)}}}):_vm._e()]),_c('br')],1)})],2)}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.insplist[0].id!=0),expression:"insplist[0].id!=0"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.inspedit(_vm.ship+'-4')}}},[_vm._v("添加记录")])]}}])})],1)],2),_c('inspedit',{attrs:{"inspedit_dialogVisible":_vm.inspedit_dialogVisible,"shipno":_vm.shipno,"ship":_vm.ship,"insplist":_vm.insplist},on:{"editclose":_vm.inspeditcls}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }