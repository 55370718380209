<template>
  <div>
    <el-dialog :visible.sync="appdialogvisible" :before-close="handleClose" width="98%">
      <slot></slot><!-- 此处slot将“添加该项目”放在了itpstatuscount -->
      <!-- 项目选择 -->
      <el-table :data="insplist" style="width: 100%;" :cell-style="{'border':'solid 1px'}"
       :header-cell-style="{'border':'solid 2px'}">
        <el-table-column prop="id" label="id" sortable width="95"> </el-table-column>
        <el-table-column prop="pname" label="设备名称" width="150"> </el-table-column>
        <el-table-column prop="name" label="名称" width="150"> </el-table-column>
        <el-table-column prop="stage" label='阶段' width="55"> </el-table-column>
        <!-- <el-table-column prop="owner" label="船东" width="40"> </el-table-column> -->
        <el-table-column prop="profession" label="专业" width="55"> </el-table-column>
        <!-- <el-table-column prop="shipyard" label="船厂" width="40"> </el-table-column> -->
        <!-- <el-table-column prop="cls" label="船检" width="40"> </el-table-column> -->
        <el-table-column :label="ship+'-1编号/日期/结果/意见'" width="250">
          <template slot-scope="{row,}">
            <span v-for="(id_insp,id_insp_index) in row.comments1" :key="id_insp_index">
              <div>
                <span style="color:gray;">{{'['+id_insp.inspno+']'}}</span>
                <span style="color:blue;"><b>{{id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result}}</b></span>
              </div>
              <span v-for="(id_comment,id_index) in id_insp.comments" :key="id_index">
                <font :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}}
                  <!-- 增加意见关闭人的姓名,如果与报验单同名则不显示,不同名就显示 -->
                  <span style="color:gold" v-if="id_insp.surveyor!=id_comment.closename && id_comment.closename">({{id_comment.closename+id_comment.closedate}})</span>
                  <i class="el-icon-error" v-if="id_comment.status" @click="clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                  <i class="el-icon-refresh-left" v-if="!id_comment.status" @click="rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                </font><br/>
              </span>
            </span>
            <el-button v-show="insplist[0].id!=0" type="primary"  @click="inspedit(ship+'-1')" size="small">添加记录</el-button>
          </template>
        </el-table-column>
        <el-table-column :label="ship+'-2编号/日期/结果/意见'" width="250">
          <template slot-scope="{row,}">
            <span v-for="(id_insp,id_insp_index) in row.comments2" :key="id_insp_index">
              <div>
                <span style="color:gray;">{{'['+id_insp.inspno+']'}}</span>
                <span style="color:blue;"><b>{{id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result}}</b></span>
              </div>
              <span v-for="(id_comment,id_index) in id_insp.comments" :key="id_index">
                <font :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}}
                  <!-- 增加意见关闭人的姓名,如果与报验单同名则不显示,不同名就显示 -->
                  <span style="color:gold" v-if="id_insp.surveyor!=id_comment.closename && id_comment.closename">({{id_comment.closename+id_comment.closedate}})</span>
                  <i class="el-icon-error" v-if="id_comment.status" @click="clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                  <i class="el-icon-refresh-left" v-if="!id_comment.status" @click="rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                </font><br/>
              </span>
            </span>
            <el-button v-show="insplist[0].id!=0" type="primary"  @click="inspedit(ship+'-2')" size="small">添加记录</el-button>
          </template>
        </el-table-column>
        <el-table-column :label="ship+'-3编号/日期/结果/意见'" width="250">
          <template slot-scope="{row,}">
            <span v-for="(id_insp,id_insp_index) in row.comments3" :key="id_insp_index">
              <div>
                <span style="color:gray;">{{'['+id_insp.inspno+']'}}</span>
                <span style="color:blue;"><b>{{id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result}}</b></span>
              </div>
              <span v-for="(id_comment,id_index) in id_insp.comments" :key="id_index">
                <font :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}}
                  <!-- 增加意见关闭人的姓名,如果与报验单同名则不显示,不同名就显示 -->
                  <span style="color:gold" v-if="id_insp.surveyor!=id_comment.closename && id_comment.closename">({{id_comment.closename+id_comment.closedate}})</span>
                  <i class="el-icon-error" v-if="id_comment.status" @click="clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                  <i class="el-icon-refresh-left" v-if="!id_comment.status" @click="rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                </font><br/>
              </span>
            </span>
            <el-button v-show="insplist[0].id!=0" type="primary"  @click="inspedit(ship+'-3')" size="small">添加记录</el-button>
          </template>
        </el-table-column>
        <el-table-column :label="ship+'-4编号/日期/结果/意见'" width="250">
          <template slot-scope="{row,}">
            <span v-for="(id_insp,id_insp_index) in row.comments4" :key="id_insp_index">
              <div>
                <span style="color:gray;">{{'['+id_insp.inspno+']'}}</span>
                <span style="color:blue;"><b>{{id_insp.surveyor+id_insp.date+' '+id_insp.time+' '+id_insp.result}}</b></span>
              </div>
              <span v-for="(id_comment,id_index) in id_insp.comments" :key="id_index">
                <font :color="[id_comment.status ? 'red':'gray']">{{id_index+1}}:{{id_comment.value}}
                  <!-- 增加意见关闭人的姓名,如果与报验单同名则不显示,不同名就显示 -->
                  <span style="color:gold" v-if="id_insp.surveyor!=id_comment.closename && id_comment.closename">({{id_comment.closename+id_comment.closedate}})</span>
                  <i class="el-icon-error" v-if="id_comment.status" @click="clsCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                  <i class="el-icon-refresh-left" v-if="!id_comment.status" @click="rstCmts(id_insp.inspno,id_index,id_insp.date,id_comment.value,id_insp.surveyor)"></i>
                </font><br/>
              </span>
            </span>
            <el-button v-show="insplist[0].id!=0" type="primary"  @click="inspedit(ship+'-4')" size="small">添加记录</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <inspedit :inspedit_dialogVisible="inspedit_dialogVisible" :shipno="shipno" :ship="ship" :insplist="insplist" @editclose="inspeditcls"></inspedit>
  </div>
</template>
<script>
  import inspedit from './inspedit'
  export default {
    components: {
      inspedit
    },
    props: [
      'insplist',
      'appdialogvisible',
      'ship'
    ],
    data () {
      return {
        inspedit_dialogVisible: false,
        shipno:'',
      }
    },
    methods: {
      inspedit (shipno,pname) {
        this.shipno=shipno
        this.inspedit_dialogVisible = true
      },
      handleClose () {
        this.$emit('clsinspappdlg')
      },
      inspeditcls () {
        this.inspedit_dialogVisible = false
        this.$emit('refreshstatuslist', this.insplist[0].id)
      },
      clsCmts (inspno, index, date, value, surveyor) {
        // console.log(surveyor)
        this.$http.post('/tp6/public/index.php/Mach/closecomments', { ship:this.ship, inspno, index, date, value, surveyor })
          .then((backdata) => {
            // console.log(backdata.data)
            this.$emit('refreshstatuslist', this.insplist[0].id)
          })
      },
      rstCmts (inspno, index, date, value, surveyor) {
        // console.log(surveyor)
        this.$http.post('/tp6/public/index.php/Mach/resetcomments', { ship:this.ship, inspno, index, date, value, surveyor })
          .then((backdata) => {
            // console.log(backdata.data)
            this.$emit('refreshstatuslist', this.insplist[0].id)
          })
        // console.log(inspno, index, date, value)
      }
    }
  }
</script>
<style scoped>
  td{
    /* background: rgb(232, 233, 231); */
    height:20px;
  }
  th{
    background: rgb(91, 181, 241);
  }
</style>
