<template>
  <div class="user">
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/login' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>活动列表</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区域 -->
    <el-card>
      <!-- 分栏区域 -->
      <el-row :gutter="1">
        <el-col :span="8">
          <el-input placeholder="请输入内容">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary"  @click="addDialogVisible = true">添加用户</el-button>
        </el-col>
      </el-row>
    </el-card>
    <!-- 用户列表区域 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      :default-sort="{prop: 'userID', order: 'descending'}"
      border
      stripe
    >
      <el-table-column type="index"  label="序号" width="100"></el-table-column>
      <el-table-column prop="userID" label="ID" sortable width="280"></el-table-column>
      <el-table-column prop="name" label="姓名" sortable width="280"></el-table-column>
      <el-table-column prop="pro" label="专业" sortable width="180"></el-table-column>
    </el-table>
        <!-- 添加用户的对话框 -->
    <el-dialog
      title="添加新用户"
      :visible.sync="addDialogVisible"
      width="40%">
      <!-- 添加用户的表单 -->
      <el-form :model="addForm" :rules="addFormRules" ref="addForm" label-width="70px">
        <el-form-item label="用户ID" prop="userID">
          <el-input v-model="addForm.userID"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="项目组">
          <el-checkbox-group v-model="addForm.team">
            <el-checkbox label="7200" name="team"></el-checkbox>
            <el-checkbox label="11k" name="team"></el-checkbox>
            <el-checkbox label="25k" name="team"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="专业" prop="pro">
          <el-select v-model="addForm.pro" placeholder="请选择">
            <el-option
              v-for="pro in prolist"
              :key="pro.value"
              :label="pro.label"
              :value="pro.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮区域 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      addDialogVisible: false,
      addForm: {
        userID: '',
        name: '',
        team: [],
        level: '3',
        pro: 'M',
        password: ''
      },
      editForm: {
      },
      prolist: [
        { value: 'H', label: 'H' },
        { value: 'M', label: 'M' },
        { value: 'P', label: 'P' },
        { value: 'E', label: 'E' },
        { value: 'Q', label: 'Q' }
      ],
      addFormRules: {
        userID: [
          { required: true, message: '请输入姓名拼音', trigger: 'blur' },
          { require: true, min: 4, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入中文姓名', trigger: 'blur' },
          { require: true, min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { require: true, min: 5, max: 15, message: '长度在 5 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    getUserList () {
      this.$http
        .get('/tp6/public/index.php/UserCtl/userlist')
        .then((backdata) => {
          this.tableData = backdata.data
        })
    },
    addUser () {
      this.$refs.addForm.validate(valid => {
        // console.log(valid)
        // 校验失败返回
        if (!valid) return
        this.$http.post('/tp6/public/index.php/UserCtl/adduser', this.addForm)
          .then((backdata) => {
            if (backdata.data !== 1) {
              this.$message.error('添加用户失败')
            }
            this.$message.success('添加用户成功')
            this.addDialogVisible = false
            // console.log(backdata.data)
          })
      })
    }
  }
}
</script>
<style scoped>
.el-breadcrumb {
  margin-bottom: 15px;
}
.user {
  padding: 0;
  /* height:100%; */
}
</style>
