<template>
  <div>
    <br>
    <div class="block">
      <span>选择周</span>
      <el-date-picker v-model="myweek" type="week" format="yyyy 第 WW 周"
        placeholder="选择周" @change="week_insplist" >
      </el-date-picker>
    </div>
    <br>
    <div>
      <el-table :data="weekreport[0]" stripe :row-style="{height:20+'px'}" :cell-style="{padding:0+'px'}"
        :header-cell-style="{background:'#D3DCE6',color:'#606266',height:'10px','padding':'0'}">
        <el-table-column prop="date" label="7200-3" sortable width="100"></el-table-column>
        <el-table-column prop="星期几" label="星期几" width="75"></el-table-column>
        <el-table-column prop="报验数" label="报验数" width="75"></el-table-column>
        <el-table-column prop="取消" label="取消" width="75"></el-table-column>
        <el-table-column prop="通过" label="通过" width="75"></el-table-column>
        <el-table-column prop="未通过" label="未通过" width="75"></el-table-column>
        <el-table-column prop="拒检" label="拒检" width="75"></el-table-column>
        <el-table-column prop="通过率" label="通过率" width="75"></el-table-column>
      </el-table>
    </div>
    <br/>
    <div>
      <el-table :data="weekreport[1]" stripe :row-style="{height:0+'px'}" :cell-style="{padding:0+'px'}"
        :header-cell-style="{background:'#D3DCE6',color:'#606266',height:'10px','padding':'0'}">
        <el-table-column prop="date" label="7200-4" sortable width="100"></el-table-column>
        <el-table-column prop="星期几" label="星期几" width="75"></el-table-column>
        <el-table-column prop="报验数" label="报验数" width="75"></el-table-column>
        <el-table-column prop="取消" label="取消" width="75"></el-table-column>
        <el-table-column prop="通过" label="通过" width="75"></el-table-column>
        <el-table-column prop="未通过" label="未通过" width="75"></el-table-column>
        <el-table-column prop="拒检" label="拒检" width="75"></el-table-column>
        <el-table-column prop="通过率" label="通过率" width="75"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>

export default {
  data () {
    return {
      myweek: new Date(),
      weekreport: []
    }
  },
  created () {
    this.week_insplist()
  },
  methods: {
    async week_insplist () {
      var weekday = this.myweek.toLocaleDateString()
      const { data: backdata } = await this.$http.post('tp6/public/index.php/Mach/weekreport', { week1st: weekday })
      this.weekreport = backdata
      // console.log(backdata)
    }
  }
}
</script>
<style scoped>
  .block {
    display: block;
    width: 300px;
    background-color:rgb(100, 154, 241);
    font-size:25px;
    color:rgb(204, 81, 32);
    padding: 5px;
  }
</style>
