// import Vue from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// Vue.config.productionTip = false

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import './assets/css/global.css'
import * as echarts from 'echarts'

// Vue.prototype.$bus = new Vue()

Vue.use(ElementUI)

// 保证每次axios请求的session值一致
axios.defaults.withCredentials = true
// axios.defaults.baseURL = 'http://localhost'
axios.defaults.baseURL = 'https://www.jzship.cn/'
Vue.prototype.$http = axios

Vue.prototype.$echarts = echarts

Vue.prototype.getNowFormatDate = function () {
  var date = new Date()
  var seperator1 = '-'
  var year = date.getFullYear()
  var month = date.getMonth() + 1
  var strDate = date.getDate()
  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate
  return currentdate
}

Vue.config.productionTip = false

new Vue({
  data: {
    event: new Vue()
  },
  router,
  render: h => h(App)
}).$mount('#app')
