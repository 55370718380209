<template>
  <div class="top" style="height:90vh; overflow: auto;">
    <el-backtop target=".top" :visibility-height="100"><i class="el-icon-caret-top"></i></el-backtop>
    <div>
      <!-- 按船 船号 类别 查看按钮 -->
      <div style="text-align:left; font-size:15px;margin:5px 10px;">
        <span style="color:blue;">注:点击表格内数字查看关联内容;</span>
        <span style="color:red;margin-left:20px">红色: 未关闭;</span>
        <span style="color:green;margin:0 20px">绿色: 已关闭;</span>
      <!-- </div>
      <div style="background-color: #EDEDF4;display:inline;"> -->
        <el-radio-group v-model="shipno" size="mini" @change="shipnosel()">
            <el-radio-button label="7200-1"></el-radio-button>
            <el-radio-button label="7200-2"></el-radio-button>
            <el-radio-button label="7200-3"></el-radio-button>
            <el-radio-button label="7200-4"></el-radio-button>
            <el-radio-button label="9200-1"></el-radio-button>
            <el-radio-button label="9200-2"></el-radio-button>
            <el-radio-button label="25k-1"></el-radio-button>
            <el-radio-button label="25k-2"></el-radio-button>
        </el-radio-group>
        <el-button type="danger" size="small" @click="dialogFormVisible = true"><span style="font-size:15px;">添加意见单</span></el-button>
      </div>
      <!-- <div style="float:left; height:210px;width:1200px"> -->
      <div style="float:left; height:210px;">
        <table style="padding:0;" cellspacing="0">
          <tr>
            <td>
              <table style="padding:0;" cellspacing="0">
                <tr>
                  <th height="33px">姓名</th>
                </tr>
                <tr height="33px">
                  <th>类型</th>
                </tr>
                <tr height="33px">
                  <td>7200-1</td>
                </tr>
                <tr height="33px">
                  <td>7200-2</td>
                </tr>
                <tr height="33px">
                  <td>7200-3</td>
                </tr>
                <tr height="33px">
                  <td>7200-4</td>
                </tr>
              </table>
            </td>
            <td v-for="(item, surveyor) in groupcount" :key="surveyor">
              <table style="padding:0;" cellspacing="0">
                <tr height="33px">
                  <td colspan="4">{{surveyor}}</td>
                </tr>
                <tr height="33px">
                  <td colspan="2">巡检</td><td colspan="2">整改</td>
                </tr>
                <tr v-for="(ship,shipno) in item" :key="shipno" height="33px">
                  <!-- <th style="background:#CECECE;">{{shipno}}</th> -->
                  <td @click="getncrlist(shipno,surveyor,'cmt','open')" width="35px"><span style="color:red;" v-show="ship['cmt']['open']">{{ship.cmt.open}}</span></td>
                  <td @click="getncrlist(shipno,surveyor,'cmt','close')" width="35px"><span style="color:#24AF2C" v-show="ship['cmt']['close']">{{ship.cmt.close}}</span></td>
                  <td @click="getncrlist(shipno,surveyor,'ncr','open')" width="35px"><span style="color:red" v-show="ship['ncr']['open']">{{ship.ncr.open}}</span></td>
                  <td @click="getncrlist(shipno,surveyor,'ncr','close')" width="35px"><span style="color:#24AF2C" v-show="ship['ncr']['close']">{{ship.ncr.close}}</span></td>
                </tr>
              </table>
            </td></tr>
          </table>
      </div>
    </div>
    <!-- 列表 -->
    <div>
      <!-- <el-table :data="ncrlist" border style="width: 90%;" height="70vh"> -->
      <el-table :data="ncrlist" border style="width: 90%;">
        <el-table-column prop="shipno" label="船号" width="70"></el-table-column>
        <el-table-column prop="no" label="编号" width="90"></el-table-column>
        <el-table-column label="意见内容" width="500">
          <template slot-scope="{row,}">
            <ol>
              <li v-for="(item,index) in row.comments" :key="index">
                <span :class="[item.status=='open' ? 'colorred':'colorgray']">
                  {{item.content}}
                  <!-- <span  :class="[item.closename==item.surveyor ? 'colorgray':'colorred']" v-if="item.closename">(操作:{{item.closename}}{{item.closedate}})</span> -->
                  <span style="color:coral" v-if="item.closename!=item.surveyor && item.closename">(操作:{{item.closename}}{{item.closedate}})</span>
                  <span v-if="item.status=='close'">关闭</span>
                  <i class="el-icon-error" v-if="item.status=='open'" @click="clsncr(item.id)"></i>
                  <i class="el-icon-refresh-left" v-if="item.status=='close'" @click="rstncr(item.id)"></i>
                </span>
              </li>
            </ol>
          </template>
        </el-table-column>
        <el-table-column prop="surveyor" label="检验人" width="70"></el-table-column>
        <el-table-column prop="creatdate" label="填写日期" width="100"></el-table-column>
        <el-table-column prop="closedate" label="关闭日期" width="100"></el-table-column>
        <el-table-column label="附件" width="200">
          <template slot-scope="{row,}">
            <a :href="row.filelink" target="_blank" v-if="row.filelink">{{row.filename}}</a>
            <el-upload
              class="upload-demo"
              action="https://www.jzship.cn/tp6/public/index.php/Mach/upload"
              accept=".pdf"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-upload="beforeupload"
              :file-list="fileList"
              :data="{no: row.no}"
              list-type="picture"
              :on-success="handleAvatarSuccess">
              <el-button type="text">上传</el-button>
            </el-upload>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表单 -->
    <el-dialog :visible.sync="dialogFormVisible" width="80%">
      <el-form :model="form" ref="form" label-width="100px">
        <el-row>
          <el-col :span="4">
            <div>
              <el-form-item prop="type" label="类型">
                <el-select v-model="form.type" style="width:120px;">
                  <el-option v-for="item in type" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-form-item label="船号" prop="shipno">
                <el-select v-model="form.shipno" placeholder="船号" style="width:120px;">
                  <el-option v-for="item in formshipno" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-form-item label="编号" prop="no" placeholder="数字"
               :rules="[{ required: true, message: '未填写'}, { type: 'number', message: '填写数字'}]">
                <el-input v-model.number="form.no" autocomplete="off" style="width:100px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <el-form-item label="日期">
                <input type="date" placeholder="选择日期" v-model="form.creatdate" style="width:160px">
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item v-for="(item, index) in form.contents" :label="item.subno+''" :key="index"
            :prop="'contents.' + index + '.content'"
            :rules="{ required: true, message: '意见不能为空', trigger: 'blur' }">
            <el-col :span="16">
              <el-input v-model="item.content"></el-input>
            </el-col>
            <el-col :span="2">
              <el-button @click.prevent="removecontent(item)" type="danger">删除</el-button>
            </el-col>
          </el-form-item>
          <el-form-item>
          <el-col :span="16">
            <el-button type="warning" @click="addncr">增加意见</el-button>
            <el-button type="primary" @click="submitForm('form')">提交</el-button>
          </el-col>
          </el-form-item>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

export default {
  props: [
    'username'
  ],
  data () {
    return {
      shipno: '7200-4',
      ship: '',
      no: '',
      surveyor: '',
      radio1: '巡检-4',
      para: {
        shipno: '',
        surveyor: '',
        type: '',
        status: ''
      },
      form: {
        no: '',
        // subno: '',
        type: 'cmt-',
        shipno: '7200-4',
        contents: [{ subno: 1, content: '' }],
        creatdate: this.getNowFormatDate()
      },
      dialogFormVisible: false,
      type: [
        { value: 'cmt-', label: '巡检意见' },
        { value: 'NCR-', label: '整改单' }
      ],
      formshipno: [
        // { value: '7200-1', label: '7200-1' },
        // { value: '7200-2', label: '7200-2' },
        { value: '7200-3', label: '7200-3' },
        { value: '7200-4', label: '7200-4' }
      ],
      // surveyor: [
      //   { value: '7200-1', label: '7200-1' },
      //   { value: '7200-2', label: '7200-2' },
      //   { value: '7200-3', label: '7200-3' },
      //   { value: '7200-4', label: '7200-4' }
      // ],
      groupcount: '',
      ncrlist: [],
      backinfo: '',
      fileList: []
    }
  },
  created () {
    this.getgroupcount()
    this.getncrlist('7200-4', '%', '%', 'open')
    // this.getncrlist('%', '%', '%', 'open')
  },
  methods: {
    shipnosel () {
        this.ship=this.shipno.split('-')[0]
        this.no=this.shipno.split('-')[1]
    },
    submitForm (formName) {
      // console.log(this.form.creatdate)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.form)
          this.$http.post('/tp6/public/index.php/Mach/ncradd', this.form)
            .then((backdata) => {
              // console.log(backdata.data)
              this.$message({
                message: '成功添加' + backdata.data + '条信息',
                type: 'success'
              })
            })
        } else {
          this.$message.error('填写错误')
          return false
        }
        this.dialogFormVisible = false
        this.getncrlist()
      })
    },
    getgroupcount () {
      this.$http.post('/tp6/public/index.php/Mach/ncrgroupcount')
        .then((backdata) => {
          this.groupcount = backdata.data
          // console.log(backdata.data)
        })
    },
    getncrlist (shipno, surveyor, type, status) {
      if (surveyor === '总计') {
        surveyor = '%'
      }
      this.para.shipno = shipno
      this.para.surveyor = surveyor
      this.para.type = type
      this.para.status = status
      // alert(shipno + surveyor + type + status)
      this.$http.post('/tp6/public/index.php/Mach/ncrlist', { shipno: shipno, surveyor: surveyor, type: type, status: status })
        .then((backdata) => {
          this.ncrlist = backdata.data
          // console.log(backdata.data)
        })
    },
    addncr () {
      this.form.contents.push({
        subno: this.form.contents.length + 1,
        content: ''
      })
    },
    removecontent (content) {
      var index = this.form.contents.indexOf(content)
      if (index !== -1) {
        this.form.contents.splice(index, 1)
      }
    },
    clsncr (ncrid) {
      this.$http.post('/tp6/public/index.php/Mach/clsncr', { ncrid: ncrid })
        .then((backdata) => {
          console.log(backdata.data)
          this.getncrlist(this.para.shipno, this.para.surveyor, this.para.type, this.para.status)
        })
    },
    rstncr (ncrid) {
      this.$http.post('/tp6/public/index.php/Mach/rstncr', { ncrid: ncrid })
        .then((backdata) => {
          // console.log(backdata.data)
          this.getncrlist(this.para.shipno, this.para.surveyor, this.para.type, this.para.status)
        })
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleAvatarSuccess (response, file, fileList) {
      this.backinfo = response
      console.log(response)
    },
    beforeupload (file) {
      console.log(file.name)
    },
    show(a){
      alert(a)
    }
  }
}
</script>

<style scoped>
  th,td {
    border: solid 1px;
    background: rgb(159, 204, 235);
  }
  .colorred {
    color:red;
  }
  .colorgray {
    color:gray;
  }
</style>
