<template>
  <div>
    <table style="margin-top:0px;">
        <tr style="line-height:18px">
          <th>7200-3:</th>
          <th style="background:#B3BFF5">总数: {{cnt['总3']}}<font color='red'>({{cnt['cmts3']}})</font></th>
          <th :width="cnt['接受3']*0.6" style="background:green">{{cnt['接受3']}}</th>
          <th :width="cnt['复检3']*0.6" style="background:yellow">{{cnt['复检3']}}</th>
          <th :width="cnt['初检3']*0.6" style="background:#C9B9AF">{{cnt['初检3']}}</th>
        </tr>
    </table>
    <table style="margin:0 0 10px 0;">
        <tr style="line-height:18px">
          <th>7200-4:</th>
          <th style="background:#B3BFF5">总数: {{cnt['总4']}}<font color='red'>({{cnt['cmts4']}})</font></th>
          <th :width="cnt['接受4']*0.6" style="background:green">{{cnt['接受4']}}</th>
          <th :width="cnt['复检4']*0.6" style="background:yellow">{{cnt['复检4']}}</th>
          <th :width="cnt['初检4']*0.6" style="background:#C9B9AF">{{cnt['初检4']}}</th>
        </tr>
    </table>
    <div ref="chart1" style="width:1200px;height:45vh;"></div>
    <div ref="chart2" style="width:1200px;height:45vh;"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      cnt: '',
      // itpcnt1: '',
      // itpcnt2: '',
      chartInstance1: null,
      chartInstance2: null,
      allData1: null,
      allData2: null
    }
  },
  mounted () {
    this.getStatusCount()
    this.initChart1()
    this.getData1()
    this.initChart2()
    this.getData2()
  },
  methods: {
    getStatusCount () {
      this.$http.post('/tp6/public/index.php/Mach/status_count')
        .then((backdata) => {
          this.cnt = backdata.data
          // this.itpcnt1 = backdata.data['总3']
          // this.itpcnt2 = backdata.data['总4']
          // console.log(backdata.data)
        })
    },
    initChart1 () {
      this.chartInstance1 = this.$echarts.init(this.$refs.chart1)
    },
    async getData1 () {
      const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/process_diagram1')
      this.allData1 = backdata
      // console.log(this.allData)
      this.updateChart1()
    },
    updateChart1 () {
      const tblname1 = Object.keys(this.allData1)
      const tblvalue1 = Object.values(this.allData1)
      // const tblname = this.allData.map((item) => { return item.dtime })
      const tblvaluejs1 = tblvalue1.map((item) => { return item.接受 })
      const tblvaluefj1 = tblvalue1.map((item) => { return item.复检 })
      // console.log(tblname1)
      // console.log(tblvalue1)
      const option1 = {
        title: {
          show: true,
          text: '7200-3报验进度'
        },
        color: ['green', 'yellow'],
        legend: {
          data: ['接受', '复检']
        },
        xAxis: {
          type: 'category',
          data: tblname1
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '接受',
            type: 'bar',
            stack: 'total',
            // label: {
            //   show: true
            // },
            emphasis: {
              focus: 'series'
            },
            data: tblvaluejs1
          },
          {
            name: '复检',
            type: 'bar',
            stack: 'total',
            // label: {
            //   show: true
            // },
            emphasis: {
              focus: 'series'
            },
            data: tblvaluefj1
          }
        ]
      }
      this.chartInstance1.setOption(option1)
    },
    initChart2 () {
      this.chartInstance2 = this.$echarts.init(this.$refs.chart2)
    },
    async getData2 () {
      const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/process_diagram2')
      this.allData2 = backdata
      // console.log(this.allData)
      this.updateChart2()
    },
    updateChart2 () {
      const tblname2 = Object.keys(this.allData2)
      const tblvalue2 = Object.values(this.allData2)
      // const tblname = this.allData.map((item) => { return item.dtime })
      const tblvaluejs2 = tblvalue2.map((item) => { return item.接受 })
      const tblvaluefj2 = tblvalue2.map((item) => { return item.复检 })
      // console.log(tblname2)
      // console.log(tblvalue2)
      const option2 = {
        title: {
          show: true,
          text: '7200-4报验进度'
        },
        color: ['green', 'yellow'],
        legend: {
          data: ['接受', '复检']
        },
        xAxis: {
          type: 'category',
          data: tblname2
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '接受',
            type: 'bar',
            stack: 'total',
            // label: {
            //   show: true
            // },
            emphasis: {
              focus: 'series'
            },
            data: tblvaluejs2
          },
          {
            name: '复检',
            type: 'bar',
            stack: 'total',
            // label: {
            //   show: true
            // },
            emphasis: {
              focus: 'series'
            },
            data: tblvaluefj2
          }
        ]
      }
      this.chartInstance2.setOption(option2)
    }
  }
}
</script>

<style scoped>
</style>
