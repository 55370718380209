<template>
  <div>
    <table>
      <tr>
        <th width='80px'>{{'7200-'+no+sname}}</th>
        <th v-for="(cols1,cols1key,cols1index) in bkdata.cols1" :key='cols1index' :colspan="cols1">{{cols1key}}</th>
      </tr>
      <tr v-show="pnameshow">
        <th>{{sname}}</th>
        <th v-for="(cols2,cols2key,cols2index) in bkdata.cols2" :key='cols2index'><div>{{cols2}}</div></th>
      </tr>
      <tr v-for="(pgroup,namekey,nameindex) in bkdata.rows" :key='nameindex'>
        <td>
          <el-tooltip placement="right">
            <div slot="content">{{namekey}}</div>
            <div style="width: 80px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{namekey}}</div>
          </el-tooltip>
        </td>
        <td v-for="(pname,pnamekey,pnameindex) in pgroup" :key='pnameindex' width="14.85px"
          :class="[pname['status'+no]=='初检' ? 'orange':'', pname['status'+no]=='复检' ? 'yellow':'', pname['status'+no]=='接受' ? 'green':'']"
          @click="inspApp(pname.id, pname)"
        ><font color='red' strong v-if="pname['cmts'+no]">{{pname['cmts'+no]}}</font></td>
      </tr>
    </table>
    <inspappdialog :appdialogvisible='appdialogvisible'  @myevent="closeInspAppDialog" @refreshstatuslist='inspApp2'
     :insplist='insplist'>
      <el-form-item v-show="itemdetail.id==0">
        <el-button type="primary"  @click="additem(itemdetail)">添加该报验项目</el-button>
      </el-form-item>
    </inspappdialog>
    <additemdialog ref="add" :itemdetail="itemdetail" :additemdialogvisible='additemdialogvisible' @closeadditemdialog="closeAddItemDialog"
      @refreshlist="gettankdata(),inspApp($event,itemdetail)"></additemdialog>
  </div>
</template>
<script>
import inspappdialog from './inspAppDialog'
import additemdialog from './addItemDialog'
export default {
  components: {
    inspappdialog,
    additemdialog
  },
  // props: [
  //   'wdh',
  //   'ppno',
  //   'sname',
  //   'no',
  //   'bt1',
  //   'bt2',
  //   'pnameshow'
  // ],
  data () {
    return {
      wdh: 20,
      ppno: 5,
      sname: '货舱',
      no: '2',
      bt1: 'info',
      bt2: 'primary',
      pnameshow: false,
      bkdata: '',
      // pnameshow: false,
      insplist: [],
      itemdetail: '',
      appdialogvisible: false,
      additemdialogvisible: false
    }
  },
  mounted () {
    this.gettankdata()
  },
  methods: {
    async gettankdata () {
      const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/itp_status_count', { ppno: this.ppno })
      this.bkdata = backdata
      console.log(this.bkdata)
    },
    inspApp (id, pname) {
      console.log(pname)
      this.$http.post('/tp6/public/index.php/Mach/id_insplist', { id: id })
        .then((backdata) => {
          this.insplist = backdata.data
          this.itemdetail = pname
          this.itemdetail.id = id
          this.appdialogvisible = true
          // console.log(this.insplist)
        })
    },
    inspApp2 (id) {
      // console.log(pname)
      this.$http.post('/tp6/public/index.php/Mach/id_insplist', { id: id })
        .then((backdata) => {
          this.insplist = backdata.data
          // this.itemdetail = pname
          // this.itemdetail.id = id
          // this.appdialogvisible = true
          // console.log(this.insplist)
        })
    },
    closeInspAppDialog () {
      this.appdialogvisible = false
    },
    additem (itemdetail) {
      // this.partdetail.pid = pid
      // this.partdetail.pname = pname
      this.$refs.add.setname()
      this.additemdialogvisible = true
      // console.log(itemdetail)
    },
    closeAddItemDialog () {
      this.additemdialogvisible = false
    }
  }
}
</script>

<style scoped>
  table {
    margin-top: 10px;
  }
  table,tr,td,th {
    padding: 0;
    border: solid 1px;
    border-spacing: 0;
    font-size: small;
    table-layout:fixed;
  }
  tr,td,th {
    margin: 0;
    line-height:18px;
  }
  th {
    /* line-height:120%; */
    background-color: rgb(179, 191, 245);
  }
  th div{
    /* word-wrap: break-all; */
    word-wrap:break-word;
    /* overflow: hidden; */
    width: 13px;
    line-height:120%;
    /* height: 30px; */
    /* vertical-align: top; */
    /* background-color: rgb(179, 191, 245); */
  }
  .orange {
    background: #C9B9AF;
  }
  .yellow {
    background: yellow;
  }
  .green {
    background: green;
  }
</style>
