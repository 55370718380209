<template>
  <!-- <el-container> -->
  <!-- 头部区域 -->
    <!-- <el-header>
      <shipnoselect @refresh="gettankdata()"></shipnoselect>
    </el-header>
    <el-main> -->
      <div style="position:relative;top:0px;">
        <table style="position:relative; top:0px;">
          <thead style="position: sticky;top:0; z-index:1">
            <tr  style="background: #B3BFF5;">
              <th>{{ship+'-'+no+sname}}</th>
              <th v-for="(cols1,cols1key,cols1index) in bkdata.cols1" :key='cols1index' :colspan="cols1">{{cols1key}}</th>
              <th rowspan="2" width="30px">统计</th>
            </tr>
            <tr v-show="pnameshow">
              <th>{{sname}}</th>
              <th v-for="(cols2,cols2key,cols2index) in bkdata.cols2" :key='cols2index'><div>{{cols2}}</div></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pgroup,namekey,nameindex) in bkdata.rows" :key='nameindex'>
              <td style="text-align:left">{{namekey}}
              </td>
              <td v-for="(pname,pnamekey,pnameindex) in pgroup.blk" :key='pnameindex' width="14.85px"
                :class="[pname['status'+no]=='进度标注' ? 'lightgreen':'', pname['status'+no]=='初检' ? 'orange':'', pname['status'+no]=='复检' ? 'yellow':'', pname['status'+no]=='接受' ? 'green':'']"
                @click="inspApp(pname.id, pname)"
              ><font color='red' strong v-if="pname['cmts'+no]">{{pname['cmts'+no]}}</font></td>
              <td><b>{{pgroup.count['count'+no]}}</b></td>
            </tr>
          </tbody>
        </table>
        <inspappdialog :appdialogvisible='appdialogvisible'  @clsinspappdlg="closeInspAppDialog" @refreshstatuslist='inspApp2'
        :insplist='insplist' :ship='ship'>
          <div v-show="itemdetail.id==0">
            <span><font size="5" color="orange">{{itemdetail.pname}}: &nbsp; &nbsp;{{itemdetail.name}}&nbsp; &nbsp;</font></span>
            <el-button type="primary"  @click="additem(itemdetail)">添加该报验项目</el-button>
          </div>
        </inspappdialog>
        <additemdialog ref="add" :ship="ship" :itemdetail="itemdetail" :additemdialogvisible='additemdialogvisible' @closeadditemdialog="closeAddItemDialog"
          @refreshlist="gettankdata(),inspApp($event,itemdetail)"></additemdialog>
      </div>
    <!-- </el-main>
  </el-container> -->
</template>
<script>
  // import shipnoselect from './shipnoselect'
  import inspappdialog from './inspAppDialog'
  import additemdialog from './addItemDialog'
  export default {
    components: {
      // shipnoselect,
      inspappdialog,
      additemdialog
    },
    props: [
      'shipno',
      'wdh',
      'ppno',
      'sname',
      'ship',
      'no',
      // // 'bt1',
      // // 'bt2',
      'pnameshow'
    ],
    data () {
      return {
        // pnameshow:'pnameshow',
        // wdh:20,
        // ship: '7200',
        // no: '3',
        // ppno:1,
        // sname:'分段',
        bkdata: '',
        // pnameshow: false,
        insplist: [],
        itemdetail: '',
        appdialogvisible: false,
        additemdialogvisible: false
      }
    },
    mounted () {
      this.gettankdata()
    },
    watch: {
      ship: function () {
        this.gettankdata()
      }
    },
    methods: {
      async gettankdata () {
        const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/itp_status_count', { ship: this.ship, no:this.no, ppno: this.ppno })
        this.bkdata = backdata
        // this.ship=this.bkdata['shipno'][0]
        // this.no=this.bkdata['shipno'][1]
        // this.no=backdata.data[1]
        // console.log(this.bkdata)
      },
      inspApp (id, pname) {
        // console.log(pname)
        this.$http.post('/tp6/public/index.php/Mach/id_insplist', { ship: this.ship, id: id})
          .then((backdata) => {
            this.insplist = backdata.data
            this.itemdetail = pname
            this.itemdetail.id = id
            this.appdialogvisible = true
            // console.log(this.insplist)
          })
      },
      inspApp2 (id) {
        // console.log(pname)
        this.$http.post('/tp6/public/index.php/Mach/id_insplist', { ship: this.ship, id: id })
          .then((backdata) => {
            this.insplist = backdata.data
            // this.itemdetail = pname
            // this.itemdetail.id = id
            // this.appdialogvisible = true
            // console.log(this.insplist)
          })
      },
      closeInspAppDialog () {
        this.appdialogvisible = false
        this.gettankdata()
      },
      additem (itemdetail) {
        // this.partdetail.pid = pid
        // this.partdetail.pname = pname
        this.$refs.add.setname()
        this.additemdialogvisible = true
        // console.log(itemdetail)
      },
      closeAddItemDialog () {
        this.additemdialogvisible = false
      }
    }
  }
</script>

<style scoped>
  table {
    margin-top: 0px;
  }
  table,tr,td,th {
    padding: 0;
    border: solid 1px;
    border-spacing: 0;
    font-size: small;
    table-layout:fixed;
  }
  tr,td,th {
    margin: 0;
    line-height:18px;
  }
  /* th {
    background-color: rgb(179, 191, 245);
  } */
  th div{
    /* word-wrap: break-all; */
    word-wrap:break-word;
    /* overflow: hidden; */
    width: 13px;
    line-height:120%;
    /* height: 30px; */
    /* vertical-align: top; */
    /* background-color: rgb(179, 191, 245); */
  }
  .lightgreen {
    background: #64FA64;
  }
  .orange {
    background: #C9B9AF;
  }
  .yellow {
    background: yellow;
  }
  .green {
    background: green;
  }
  table {
     overflow: hidden;
  } 
  td, th {
    position: relative; 
    /* width: 40px; */
    text-align: center;
  }  
  tr:hover {
    background-color: rgb(255, 0, 234);
  }  
  td:hover::after {
    content: ""; 
    position: absolute;  
    width: 100%;  
    height: 10000px;  
    left: 0; 
    top: -5000px; 
    background-color: rgb(255, 0, 234);  
    z-index: -1; 
  }
</style>