<template>
  <div class="login-wrap">
    <el-form ref="loginFormRef" :rules="rules" v-bind:model="loginform" label-width="80px" class="login-form">
      <!-- <h2>{{billlist}}</h2> -->
      <el-form-item label="用户名" prop="username">
        <el-input v-model="loginform.userID"></el-input>
      </el-form-item>
      <el-form-item label-position="top" label="密码" prop="password">
        <el-input type="password" v-model="loginform.password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" class="login-btn" @click="login">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loginform: {
        userID: '',
        password: ''
      },
      loginmsg: '',
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 20, message: '长度在 5 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    subbmit () {
      this.$refs.loginFormRef.validate(async valid => {
        console.log(valid)
        const result = await this.$http.get('index.php/', this.loginform)
        // console.log(result.status)
        // console.log(result.data)
        if (result.status !== 200) return this.$message.error('php未接收到')
        this.$message.success('php已接收到')
        this.$router.push('tpdb')
      })
    },
    async login () {
      const { data: backdata } = await this.$http.post('/tp6/public/index.php/Login/login', this.loginform)
      if (backdata === 'loginsuccess') {
        this.loginmsg = '登录成功'
        this.$router.push({ name: 'hull' })
      } else {
        this.loginmsg = '姓名或密码错误'
      }
    }
  }
}
</script>
<style>
.login-wrap {
  background-color: #324152;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-wrap .login-form {
  background-color: #fff;
  width: 400px;
  padding: 30px;
  border-radius: 5px;
}

.login-wrap .login-form .login-btn {
  width: 100%;
}
</style>
