import Vue from 'vue'
import VueRouter from 'vue-router'
import OverView from '../views/overview.vue'
import OverView2 from '../views/overview2.vue'
import OverView3 from '../views/overview3.vue'
import Login from '../views/login/Login.vue'
import Home from '../views/Home'
import User from '../components/user'
import dayInsplist from '../components/itpitem/dayInsplist'
import daysInsplist from '../components/itpitem/daysInsplist'
import blockDetail from '../components/itpitem/blockdetail'
import weekreport from '../components/itpitem/weekreport'
import itpmanage from '../components/itpitem/itpmanage'
import itpmanageblock from '../components/itpitem/itpmanageblock'
import comments from '../components/itpitem/comments'
import ncrlist from '../components/itpitem/ncrlist'
import test from '../components/itpitem/test'
import itpStatusCount from '../components/itpitem/itpStatusCount'
import hull from '../components/itpitem/hull'
import block_erect from '../components/itpitem/block_erect'
import shipnoselect from '../components/itpitem/shipnoselect'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'login', component: Login },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    children: [
      { path: '/user', component: User },
      { path: '', name: 'hull', component: hull },
      { path: '/overview', component: OverView },
      { path: '/overview2', component: OverView2 },
      { path: '/overview3', component: OverView3 },
      { path: '/dayInsplist', component: dayInsplist },
      { path: '/daysInsplist', component: daysInsplist },
      { path: '/blockdetail', component: blockDetail },
      { path: '/weekreport', component: weekreport },
      { path: '/itpmanage', component: itpmanage },
      { path: '/itpmanageblock', component: itpmanageblock },
      { path: '/ncrlist', component: ncrlist },
      { path: '/comments', component: comments },
      { path: '/test', component: test },
      { path: '/itpStatusCount', component: itpStatusCount },
      // { path: '/processShow', component: processShow },
      // { path: '/dwg', component: dwg },
      { path: '/hull', component: hull },
      { path: '/block_erect', component: block_erect },
      { path: '/shipnoselect', component: shipnoselect }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
