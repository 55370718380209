<!-- <template>
  <div>
    <div style="position:absolute; top:0px;height:37px;width:1200px;background:white;border:solid 2px blue;">
      <table style="font-weight:bold;">
        <tr>
          <td><div style="width:20px;height:20px;background:#C9B9AF"></div></td><td>--未报验</td><td style="width:20px"></td>
          <td><div style="width:20px;height:20px;background:yellow"></div></td><td>--复检</td><td style="width:20px"></td>
          <td><div style="width:20px;height:20px;background:green"></div></td><td>--已接受</td><td style="width:20px"></td>
          <td style="color:blue; width:250px;">注: 点击数字可查看关联报验<br><font color="red">红色:意见数量;</font><font color="b">黑色:项目数量</font></td>
          <td style="width:50px;">7200吨-</td>
          <td>
            <el-radio-group v-model="shipno" size="mini" @change="no=shipno">
              <el-radio-button label="1"></el-radio-button>
              <el-radio-button label="2"></el-radio-button>
              <el-radio-button label="3"></el-radio-button>
              <el-radio-button label="4"></el-radio-button>
            </el-radio-group>
          </td>
          <td><el-switch v-model="pnameshow" active-text="显示" inactive-text="名称隐藏"></el-switch></td>
        </tr>
      </table>
    </div>
    <div>
      <div style="height:37px;"></div>
      <table>
        <tr>
          <td>
            <itpStatusCount :pnameshow='pnameshow' :no='no' :wdh=20 :ppno=5 sname='舱室'></itpStatusCount>
          </td>
          <td width="5px"></td>
          <td>
            <itpStatusCount :pnameshow='pnameshow' :no='no' :wdh=20 :ppno=6 sname='货舱'></itpStatusCount>
          </td>
        </tr>
      </table>
      <itpStatusCount :pnameshow='pnameshow' :no='no' :wdh=20 :ppno=2 sname='管系'></itpStatusCount>
    </div>
  </div>
</template> -->
<template>
  <el-container>
    <el-header style="margin:0;padding: 0;" height="15px">
      <div style="background-color: #EDEDF4;">
          <el-radio-group v-model="shipno" size="mini" @change="shipnosel()">
              <el-radio-button label="7200-1"></el-radio-button>
              <el-radio-button label="7200-2"></el-radio-button>
              <el-radio-button label="7200-3"></el-radio-button>
              <el-radio-button label="7200-4"></el-radio-button>
              <el-radio-button label="9200-1"></el-radio-button>
              <el-radio-button label="9200-2"></el-radio-button>
              <el-radio-button label="25k-1"></el-radio-button>
              <el-radio-button label="25k-2"></el-radio-button>
          </el-radio-group>
      </div>
    </el-header>
    <el-main>
      <div style="height:37px;"></div>
      <table>
        <tr>
          <td>
            <itpStatusCount :pnameshow='pnameshow' :ship='ship' :no='no' :wdh=20 :ppno=5 sname='舱室'></itpStatusCount>
          </td>
          <td width="5px"></td>
          <td>
            <itpStatusCount :pnameshow='pnameshow' :ship='ship' :no='no' :wdh=20 :ppno=6 sname='货仓'></itpStatusCount>
          </td>
        </tr>
      </table>
      <itpStatusCount :pnameshow='pnameshow' :ship='ship' :no='no' :wdh=20 :ppno=2 sname='管系'></itpStatusCount>
    </el-main>
  </el-container>
</template>
<script>
import itpStatusCount from '../components/itpitem/itpStatusCount'
export default {
  components: {
    itpStatusCount
  },
  data () {
    return {
      shipno: '7200-3',
      ship: '7200',
      no: '3',
      // cnt: '',
      pnameshow: true
    }
  },
  mounted () {
    this.shipnosel()
  },
  methods: {
    shipnosel () {
        // alert(this.shipno.split('-')[0])
        this.ship=this.shipno.split('-')[0]
        this.no=this.shipno.split('-')[1]
    }
  }
}
</script>

<style scoped>
  table,td,th,tr {
    /* width:5px; */
    height:5px;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    table-layout:fixed;
    line-height:18px;
    font-size: small;
    /* border: solid 1px; */
  }
</style>