<template>
  <div>
    <!-- 使用table横向排列 -->
    <!-- <br/> -->
    <table>
      <tr>
        <th>1#分段</th>
        <th v-for="(item,key,index) in blockdetail[0]" :key='index'><div>{{item.block}}</div></th>
      </tr>
      <tr>
        <td>电舾件</td>
        <td v-for="(item,key,index) in blockdetail[0]" :key='index'
          :class="[item.dxj=='初检' ? 'orange':'',item.dxj=='复检' ? 'yellow':'',item.dxj=='接受' ? 'green':'']"
          @click="getdetail(item['id'],'7200-1','dxj')" >
        </td>
      </tr>
      <tr>
        <td>预埋管</td>
        <td v-for="(item,key,index) in blockdetail[0]" :key='index'
        :class="[item.ymg=='初检' ? 'orange':'',item.ymg=='复检' ? 'yellow':'',item.ymg=='接受' ? 'green':'']"
          @click="getdetail(item['id'],'7200-1','ymg')" >
          </td>
      </tr>
      <tr>
        <td>槽壁钢管</td>
        <td v-for="(item,key,index) in blockdetail[0]" :key='index'
        :class="[item.bxg=='初检' ? 'orange':'',item.bxg=='复检' ? 'yellow':'',item.bxg=='接受' ? 'green':'']"
        @click="getdetail(item['id'],'7200-1','bxg')" ></td>
      </tr>
      <tr>
        <td>盘管架PT</td>
        <td v-for="(item,key,index) in blockdetail[0]" :key='index'
          :class="[item.gpt=='初检' ? 'orange':'',item.gpt=='复检' ? 'yellow':'',item.gpt=='接受' ? 'green':'']"
          @click="getdetail(item['id'],'7200-1','gpt')" ></td>
      </tr>
    </table>
    <br/>
    <table>
      <tr>
        <th>2#分段</th>
        <th v-for="(item,key,index) in blockdetail[1]" :key='index'><div>{{item.block}}</div></th>
      </tr>
      <tr>
        <td>电舾件</td>
        <td v-for="(item,key,index) in blockdetail[1]" :key='index'
          :class="[item.dxj=='初检' ? 'orange':'',item.dxj=='复检' ? 'yellow':'',item.dxj=='接受' ? 'green':'']"
          @click="getdetail(item['id'],'7200-2','dxj')" >
        </td>
      </tr>
      <tr>
        <td>预埋管</td>
        <td v-for="(item,key,index) in blockdetail[1]" :key='index'
        :class="[item.ymg=='初检' ? 'orange':'',item.ymg=='复检' ? 'yellow':'',item.ymg=='接受' ? 'green':'']"
          @click="getdetail(item['id'],'7200-2','ymg')" >
          </td>
      </tr>
      <tr>
        <td>槽壁钢管</td>
        <td v-for="(item,key,index) in blockdetail[1]" :key='index'
        :class="[item.bxg=='初检' ? 'orange':'',item.bxg=='复检' ? 'yellow':'',item.bxg=='接受' ? 'green':'']"
        @click="getdetail(item['id'],'7200-2','bxg')" ></td>
      </tr>
      <tr>
        <td>盘管架PT</td>
        <td v-for="(item,key,index) in blockdetail[1]" :key='index'
          :class="[item.gpt=='初检' ? 'orange':'',item.gpt=='复检' ? 'yellow':'',item.gpt=='接受' ? 'green':'']"
          @click="getdetail(item['id'],'7200-2','gpt')" ></td>
      </tr>
    </table>
    <el-dialog title="报验列表" :visible.sync="dialogvisible" width="90%">
      <el-table :data="block_inspist" stripe style="width: 100%">
        <el-table-column prop="inspno" label="NO" sortable width="60"></el-table-column>
        <el-table-column prop="shipno" label="船号" sortable width="75"></el-table-column>
        <el-table-column prop="date" label="日期" sortable width="100"></el-table-column>
        <el-table-column prop="time" label="时间" width="65"></el-table-column>
        <el-table-column prop="pname" label="设备" width="80"></el-table-column>
        <el-table-column prop="name" label="项目名称" width="200"></el-table-column>
        <el-table-column prop="place" label="地点" width="100"></el-table-column>
        <el-table-column prop="qc" label="QC" width="68"></el-table-column>
        <el-table-column prop="times" label="次数" width="28"></el-table-column>
        <el-table-column prop="cls" label="船检" width="28"></el-table-column>
        <el-table-column prop="owner" label="船东" width="28"></el-table-column>
        <el-table-column prop="comments" label="意见" width="250">
          <template slot-scope="{row,}">
            <span v-for="(comment,index) in row.comments" :key="index">
              {{index+1}}:{{comment.value}}
              <i class="el-icon-error" v-if="comment.status" style="color:red" @click="clsCmts(row.inspno,index,comment.date,comment.value,row.surveyor)"></i>
              <i class="el-icon-refresh-left" v-if="!comment.status" style="color:red" @click="rstCmts(row.inspno,index,comment.date,comment.value,row.surveyor)"></i>
              <br/>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="surveyor" label="检验人" width="70"></el-table-column>
        <el-table-column prop="result" label="状态" width="60"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    data () {
      return {
        dialogvisible: false,
        blockdetail: [],
        block_inspist: '',
        refreshdata: {
          pid: '',
          shipno: '',
          name: ''
        }
      }
    },
    created () {
      this.getBlockDetail()
    },
    methods: {
      // addClass ({ row, column, rowIndex, columnIndex }) {
      //   if (columnIndex === 1) {
      //     if (row.ymg === '初检') {
      //       return 'cell-red'
      //     } else if (row.ymg === '复检') {
      //       return 'cell-yellow'
      //     } else if (row.ymg === '接受') {
      //       return 'cell-green'
      //     } else {
      //       return 'cell-grey'
      //     }
      //   }
      //   if (columnIndex === 2) {
      //     if (row.bxg === '初检') {
      //       return 'cell-red'
      //     } else if (row.bxg === '复检') {
      //       return 'cell-yellow'
      //     } else if (row.bxg === '接受') {
      //       return 'cell-green'
      //     } else {
      //       return 'cell-grey'
      //     }
      //   }
      //   if (columnIndex === 3) {
      //     if (row.dxj === '初检') {
      //       return 'cell-red'
      //     } else if (row.dxj === '复检') {
      //       return 'cell-yellow'
      //     } else if (row.dxj === '接受') {
      //       return 'cell-green'
      //     } else {
      //       return 'cell-grey'
      //     }
      //   }
      //   if (columnIndex === 3) {
      //     if (row.gpt === '初检') {
      //       return 'cell-red'
      //     } else if (row.gpt === '复检') {
      //       return 'cell-yellow'
      //     } else if (row.gpt === '接受') {
      //       return 'cell-green'
      //     } else {
      //       return 'cell-grey'
      //     }
      //   }
      // },
      async getBlockDetail () {
        const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/blockdetail')
        this.blockdetail = backdata
        // console.log(this.blockdetail)
      },
      async getdetail (pid, shipno, name) {
        this.refreshdata.pid = pid
        this.refreshdata.shipno = shipno
        this.refreshdata.name = name
        const { data: backdata } = await this.$http.post('/tp6/public/index.php/Mach/block_insplist', this.refreshdata)
        this.block_inspist = backdata
        this.dialogvisible = true
        // console.log(this.refreshdata)
        // console.log(this.block_inspist)
      },
      closeInspAppDialog () {
        this.appdialogvisible = false
      },
      clsCmts (inspno, index, date, value, surveyor) {
        this.$http.post('/tp6/public/index.php/Mach/closecomments', { inspno, index, date, value, surveyor })
          .then((backdata) => {
            this.getdetail(this.refreshdata.pid, this.refreshdata.shipno, this.refreshdata.name)
          })
        // console.log(id, shipno)
      },
      rstCmts (inspno, index, date, value, surveyor) {
        this.$http.post('/tp6/public/index.php/Mach/resetcomments', { inspno, index, date, value, surveyor })
          .then((backdata) => {
            this.getdetail(this.refreshdata.pid, this.refreshdata.shipno, this.refreshdata.name)
          })
        // console.log(id, shipno)
      }
    }
  }
</script>
<style scoped>
  table{
    padding: 0;
    margin: 0;
    border-spacing: 0;
    border: solid 1px;
  }
  td,th,tr {
    /* width:5px; */
    height:10px;
    padding: 0;
    margin: 0;
    border-spacing: 0;
    border: solid 1px;
    table-layout:fixed;
    line-height:22px;
  }
  td {
    font-size: small;
  }
  th div{
    word-wrap: break-all;
    word-wrap:break-word;
    /* overflow: hidden; */
    width: 15px;
    line-height:18px;
  }
  th {
    background: rgb(132, 151, 235);
  }
  .orange {
    background: #C9B9AF;
  }
  .yellow {
    background: yellow;
  }
  .green {
    background: green;
  }
</style>
